import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { RouteInfo } from './configuration/sidenav.interface';
import { AuthenticationService } from '@app-core/authentication/authentication.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
})
export class SidenavComponent implements OnChanges {
  public _openDrawer: boolean;
  @Input() openDrawer: boolean;
  @Output() onCloseDrawer = new EventEmitter<any>();
  @Input() username: any;
  @Input() companyLogo: any;
  activateMarquee = false;
  routes: RouteInfo[];

  constructor(private authenticationService: AuthenticationService, private changeDetector: ChangeDetectorRef, private router: Router) {
    const ROUTES: any = JSON.parse(localStorage.getItem('menuPermissions'))
      .filter((menu) => {
        return menu.active;
      })
      .map((menu) => {
        return { ...menu };
      });
    if (localStorage.getItem('apiKey') === 'sc202104Arr10' || localStorage.getItem('apiKey') === 'sc202106Dem17') {
      this.routes = ROUTES;
    } else {
      this.routes = ROUTES.filter((route: RouteInfo) => {
        return route.title !== 'Labels';
      });
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    this.changeDetector.detectChanges();

    if (changes?.username?.currentValue) {
      changes.username.currentValue.length > 6 ? (this.activateMarquee = true) : (this.activateMarquee = false);
    }
  }

  public goToTMS(): void {
    if (window.location.href.indexOf('localhost') > -1) {
      window.location.href = 'http://localhost:3000';
    } else if (window.location.href.indexOf('admin-dev.starkcore.com') > -1) {
      window.location.href = 'https://development.starkcore.com/';
    } else if (window.location.href.indexOf('admin-stg.starkcore') > -1) {
      window.location.href = 'https://demo.starkcore.com';
    } else if (window.location.href.indexOf('admin-uat.starkcore.com') > -1) {
      window.location.href = 'https://tms-uat.starkcore.com';
    } else if (window.location.href.indexOf('ltladmin.arrivelogistics.com') > -1) {
      window.location.href = 'https://ltl.arrivelogistics.com';
    } else {
      window.location.href = 'https://tms.starkcore.com';
    }
  }

  logout(): void {
    this.authenticationService.logout();
    localStorage.removeItem('token');
    localStorage.removeItem('menuPermissions');
    localStorage.removeItem('email');
    localStorage.removeItem('username');
    this.router.navigate(['/login']);
  }
}
