import { Component, Input, OnChanges } from '@angular/core';
import { UtilitiesService } from '@app-main/services/utilities/utilities.service';

@Component({
  selector: 'app-object-visualizer',
  templateUrl: './object-visualizer.component.html',
  styleUrls: ['./object-visualizer.component.scss'],
})
export class ObjectVisualizerComponent implements OnChanges {
  @Input() element: any;

  public objectValue: any;
  public firstObjectLabel: any;
  public firstObjectValue: any;
  public stringValue: any;

  public vctAttributes: any[];

  public isObjectInfoExpanded = false;

  constructor(private _utilitiesService: UtilitiesService) {}

  ngOnChanges(): void {
    this.objectValue = this._utilitiesService.getSusObjAsObj(this.element);

    if (typeof this.objectValue === 'object' && !Array.isArray(this.objectValue)) {
      const objKeys = Object.keys(this.objectValue);
      this.firstObjectLabel = objKeys[0];
      this.firstObjectValue = this.objectValue[objKeys[0]];
      this.stringValue = null;

      this.vctAttributes = this.getObjAsAnArray(this.objectValue);
    } else {
      this.firstObjectLabel = null;
      this.firstObjectValue = null;
      this.stringValue = this.element;
    }
  }

  public isAttribAnObj(attr): boolean {
    return this._utilitiesService.isThisAnObject(attr);
  }

  public getObjAsAnArray(obj): any {
    const objKeys = Object.keys(obj);
    const vctAttrib = [];

    objKeys.forEach((key) => {
      const newObj = {
        label: key,
        value: obj[key],
        isPrimitive: !this.isAttribAnObj(obj[key]),
      };

      vctAttrib.push(newObj);
    });

    return vctAttrib;
  }
}
