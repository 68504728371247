<mat-toolbar class="starkcore-toolbar" fxLayout="row" fxLayoutAlign="start center">
  <button mat-icon-button fxHide.gt-xs fxShow (click)="doOpenDrawer()">
    <mat-icon>menu</mat-icon>
  </button>
  <img [src]="companyLogo" height="32" />
  <span fxFlex></span>
  <app-sys-label info="environment"></app-sys-label>
  <button class="button-avatar" mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu" fxHide.gt-sm fxShow>
    {{ capitalCharUsername }}
  </button>
  <mat-menu #menu="matMenu">
    <div class="toolbar-profile-data" fxLayout="column" fxLayoutAlign="center center">
      <div class="profile-avatar">
        <img src="assets/icons/icon_user_white.svg" />
      </div>
      <span class="subtitle-1 primary-text">{{ username | titlecase }}</span>
      <span class="greyMedium-text">{{ scAccount }}</span>
    </div>
    <mat-divider></mat-divider>
    <button mat-menu-item (click)="logout()">
      <mat-icon>logout</mat-icon>
      <span>Logout</span>
    </button>
  </mat-menu>
</mat-toolbar>
