import { Component, Input } from '@angular/core';
import { UtilitiesService } from '@app-main/services/utilities/utilities.service';

@Component({
  selector: 'app-object-element',
  templateUrl: './object-element.component.html',
  styleUrls: ['./object-element.component.scss'],
})
export class ObjectElementComponent {
  @Input() obj: any;

  constructor(private _utilitiesService: UtilitiesService) {}

  public setAsObject(obj): any {
    return this._utilitiesService.getSusObjAsObj(obj);
  }

  public isAttribAnObj(attr): boolean {
    return this._utilitiesService.isThisAnObject(attr);
  }

  public getObjAsAnArray(obj): any {
    const objKeys = Object.keys(this.setAsObject(obj));
    const vctAttrib = [];

    objKeys.forEach((key) => {
      const newObj = {
        label: key,
        value: obj[key],
        isPrimitive: !this.isAttribAnObj(obj[key]),
      };

      vctAttrib.push(newObj);
    });

    return vctAttrib;
  }
}
