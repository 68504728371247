import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AngularMaterialModule } from '@shared/angular-material/angular-material.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FilterTablePipe } from '@shared/pipes/filterTable.pipe';
import { FilterVctStrPipe } from './pipes/filterVctStr.pipe';
import { MyFilterPipe } from '@shared/pipes/multipleFilterTable.pipe';
import { ShowcaseComponent } from './starkCore-admin-theme/showcase/showcase.component';
import { NgxMaskModule } from 'ngx-mask';
import { GenericDialogComponent } from './components/generic-dialog/generic-dialog.component';
import { AlertDialogComponent } from './components/alert-dialog/alert-dialog.component';
import { EditUserComponent } from './components/edit-user/edit-user.component';
import { PaginatorDirective } from './directives/paginator.directive';
import { CreateCompaniesGroupComponent } from './components/create-companies-group/create-companies-group.component';
import { CompanyGroupComponent } from './components/company/company-group/company-group.component';
import { CompanyListComponent } from './components/company/company-list/company-list.component';
import { EditUserArriveComponent } from './components/edit-user/edit-user-arrive/edit-user-arrive.component';
import { SearchButtonComponent } from './components/search-button/search-button.component';
import { SearchBarComponent } from './components/search-bar/search-bar.component';
import { UpliftRoundPipe } from './pipes/upliftRound.pipe';
import { DrawerHeaderComponent } from './components/drawer-header/drawer-header.component';
import { EmptyStateComponent } from './components/empty-state/empty-state.component';
import { RoleSubroleFilterSelectorComponent } from './components/role-subrole-filter-selector/role-subrole-filter-selector.component';
import { DialogHeaderComponent } from './components/dialog-header/dialog-header.component';
import { InfoCellComponent } from './components/info-cell/info-cell.component';
import { BadgeComponent } from './components/badge/badge.component';
import { SysLabelComponent } from './components/sys-label/sys-label.component';
import { SideScrollHelpComponent } from './components/side-scroll-help/side-scroll-help.component';
import { LoadingStateComponent } from './components/loading-state/loading-state.component';
import { ExactTimeToNowPipe } from './pipes/exact-time-to-now.pipe';

@NgModule({
  declarations: [
    FilterTablePipe,
    FilterVctStrPipe,
    UpliftRoundPipe,
    ExactTimeToNowPipe,
    ShowcaseComponent,
    GenericDialogComponent,
    AlertDialogComponent,
    MyFilterPipe,
    EditUserComponent,
    EditUserArriveComponent,
    PaginatorDirective,
    CompanyGroupComponent,
    CompanyListComponent,
    CreateCompaniesGroupComponent,
    SearchButtonComponent,
    SearchBarComponent,
    DrawerHeaderComponent,
    EmptyStateComponent,
    RoleSubroleFilterSelectorComponent,
    DialogHeaderComponent,
    InfoCellComponent,
    BadgeComponent,
    SysLabelComponent,
    SideScrollHelpComponent,
    LoadingStateComponent,
  ],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, AngularMaterialModule, NgxMaskModule.forRoot(), FlexLayoutModule],
  entryComponents: [GenericDialogComponent, AlertDialogComponent],
  exports: [
    AngularMaterialModule,
    FilterTablePipe,
    FilterVctStrPipe,
    UpliftRoundPipe,
    ExactTimeToNowPipe,
    NgxMaskModule,
    MyFilterPipe,
    EditUserComponent,
    PaginatorDirective,
    EditUserArriveComponent,
    CompanyGroupComponent,
    CompanyListComponent,
    CreateCompaniesGroupComponent,
    SearchButtonComponent,
    SearchBarComponent,
    DrawerHeaderComponent,
    EmptyStateComponent,
    RoleSubroleFilterSelectorComponent,
    DialogHeaderComponent,
    InfoCellComponent,
    BadgeComponent,
    SysLabelComponent,
    SideScrollHelpComponent,
    LoadingStateComponent,
  ],
})
export class SharedModule {}
