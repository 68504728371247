import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AlertService } from '@app-main/services/alerts/alert.service';
import { UserService } from '@app-main/services/user/user.service';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import * as _ from 'lodash';
import { LabelService } from '@app-main/services/labels/label.service';
import { ACCOUNT_ADMIN, CUSTOMER, SUPER_ADMIN } from '@app-main/users/add-user/user-permisions/user-permission.constants';
import { CompanyListComponent } from '@shared/components/company/company-list/company-list.component';
import { CompanyGroupComponent } from '@shared/components/company/company-group/company-group.component';
import { UserPayloadService } from '@app-main/users/service/user-payload.service';
import { CompanyService } from '@app-main/services/company/company.service';
import { accountConfiguration } from '../../../config.json';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { displayNameMap } from '@shared/models/Breakpoint.model';

@Component({
  selector: 'app-edit-user-arrive',
  templateUrl: './edit-user-arrive.component.html',
  styleUrls: ['./edit-user-arrive.component.scss'],
})
export class EditUserArriveComponent implements OnInit, OnDestroy {
  public currentScreenSize: string;
  ngUnsubscribe = new Subject<void>();
  public accountConfiguration: any;

  @Input() title: string;
  @Input() userInfo: any;

  @Output() hideEditSection = new EventEmitter<void>();

  @ViewChild('companyList', { static: false }) companyList: CompanyListComponent;
  @ViewChild('companyGroup', { static: false }) companyGroup: CompanyGroupComponent;

  userForm: FormGroup;
  regex = /([A-Z])/;
  emailRegex = '^[A-z0-9._%+-]+@[A-z0-9.-]+\\.[A-z]{2,4}$';

  /************************ */
  offices = [];
  userTypes = [{ ...ACCOUNT_ADMIN }, { ...SUPER_ADMIN }, { ...CUSTOMER }];
  roles = [];
  subRoles = [];
  hiddeBossSelect = false;
  bosses = [];
  change = false;
  difference = false;
  containedCompanies = [];
  companiesAssociated = 0;

  get hideTabsForArrive(): boolean {
    return this.accountConfiguration?.arrive && localStorage.getItem('arriveRole') === 'sales representative';
  }

  constructor(
    private _breakpointObserver: BreakpointObserver,
    private alertService: AlertService,
    private userService: UserService,
    private formBuilder: FormBuilder,
    private labelService: LabelService,
    private userPayloadService: UserPayloadService,
    private companyService: CompanyService
  ) {
    this.accountConfiguration = accountConfiguration.find((element) => {
      return element.apiKey === localStorage.getItem('apiKey');
    });
    this.getCompaniesList();

    this._breakpointObserver
      .observe([Breakpoints.XSmall, Breakpoints.Small, Breakpoints.Medium, Breakpoints.Large, Breakpoints.XLarge])
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((result) => {
        for (const query of Object.keys(result.breakpoints)) {
          if (result.breakpoints[query]) {
            this.currentScreenSize = displayNameMap.get(query);
          }
        }
      });
  }

  ngOnInit(): void {
    this.companiesAssociated = this.userInfo.companyRestrictions.length;

    this.userService.getRolesForArrive().subscribe(({ data }) => {
      if (data.length) {
        this.roles = data.map((role) => ({ value: role, viewValue: role }));
      }
    });

    this.userForm = this.formBuilder.group(
      {
        profileInformation: this.formBuilder.group({
          firstName: [this.userInfo.profileInformation.firstName, Validators.required],
          lastName: [this.userInfo.profileInformation.lastName, Validators.required],
          userName: [{ value: this.userInfo.username, disabled: true }, [Validators.required, Validators.pattern(this.emailRegex)]],
          occupation: [this.userInfo.arriveRole],
          phone: [this.userInfo.profileInformation.phone],
          password: ['', []],
          confirmPassword: ['', []],
        }),

        permissions: this.formBuilder.group({
          office: new FormControl(this.userInfo.office.toUpperCase(), [Validators.required]),
          userType: new FormControl(this.userInfo.userRole, []),
          role: new FormControl(this.userInfo.arriveRole, [Validators.required]),
          subRole: new FormControl(this.userInfo.subrole.toLowerCase(), [Validators.required]),
          boss: new FormControl(this.userInfo.boss ? this.userInfo?.boss[0]?.userId || '' : '', [Validators.required]),
        }),
      },
      { validators: this.confirmPassword }
    );

    this.getOffices();
    this.getSubRoles(this.userInfo.arriveRole, false);
    this.getInmmediateBoss(this.userInfo.arriveRole, true);
  }

  private getOffices() {
    this.labelService
      .getOffices()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(({ data }) => {
        this.offices = data;
      });
  }

  getSubRoles(rol: string, reset = true) {
    this.userForm.get('permissions').get('userType').reset();
    if (reset) this.userForm.get('permissions').get('subRole').reset();
    this.subRoles = [];
    this.userService.getSubRolesForArrive(rol).subscribe(({ data }) => {
      if (data[0]?.permissions.length) {
        this.subRoles = data[0].permissions.map((subRole) => ({ value: subRole, viewValue: subRole }));
        this.userForm.get('permissions').get('subRole').enable();
        this.userForm.get('permissions').get('userType').setValue(data[0]?.userType);
      } else {
        this.userForm.get('permissions').get('subRole').clearValidators();
        this.userForm.get('permissions').get('subRole').updateValueAndValidity();
      }
    });
  }

  onChangeRole(rol: string) {
    this.getInmmediateBoss(rol);
    this.getSubRoles(rol);
  }

  confirmPassword(group: FormGroup) {
    if (
      group.get('profileInformation').get('password').value !== group.get('profileInformation').get('confirmPassword').value &&
      group.get('profileInformation').get('password').value !== ''
    ) {
      group.get('profileInformation').get('confirmPassword').setErrors({ password: true });
      return { password: true };
    }
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  public onEnableRol(office) {
    this.userForm.get('permissions').get('boss').reset();
    this.userForm.get('permissions').get('userType').enable();
    this.userForm.get('permissions').get('office').setValue(office);
    if (this.userForm.get('permissions').get('role').value) {
      this.getInmmediateBoss(this.userForm.get('permissions').get('role').value);
    }
  }

  public getInmmediateBoss(rol, isInit = false) {
    if (!isInit) {
      this.userForm.get('permissions').get('boss').reset();
    }

    if (rol === 'director' || rol === 'customer') {
      this.hiddeBossSelect = true;
      this.userForm.get('permissions').get('boss').setValue('');
      this.userForm.get('permissions').get('boss').clearValidators();
      this.userForm.get('permissions').get('boss').updateValueAndValidity();
      return;
    } else {
      this.hiddeBossSelect = false;
      this.userForm.get('permissions').get('boss').setValidators([Validators.required]);
      this.userForm.get('permissions').get('boss').updateValueAndValidity();
    }

    this.bosses = [];
    this.userService
      .getInmediateBoss(this.userForm.get('permissions').get('office').value, rol)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(({ data }) => {
        this.bosses = data;
        this.userForm.get('permissions').get('boss').enable();
      });
  }

  public onHideEdit() {
    if (this.userForm.dirty || this.existChanges) {
      this.alertService
        .confirm('Do you want to continue without saving?')
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((resp) => {
          if (resp) {
            this.hideEditSection.emit();
          }
        });
    } else {
      this.hideEditSection.emit();
    }
  }

  public onSave() {
    if (!this.userForm.valid) {
      this.alertService.alert('Some required fields are not valid ');
      return;
    }
    this.userPayloadService.setUserArrive(true);
    const permissions = this.userForm.getRawValue().permissions;
    const profileInformation = {
      ...this.userForm.get('profileInformation').value,
      userName: this.userForm.get('profileInformation').get('userName').value,
    };
    const notifications = {
      shipment: this.userInfo.emailNotifications.shipment,
      desktop: this.userInfo.customStarkCore.notifications.desktop,
    };
    permissions.boss = this.bosses.filter((item) => {
      if (this.userForm.getRawValue().permissions.boss && this.userForm.getRawValue().permissions.boss.includes(item._id)) {
        return item;
      }
    });

    const company = { id: this.userInfo.company._id, name: this.userInfo.companyName };

    const payload = this.userPayloadService.getPayloadCreate(
      permissions,
      profileInformation,
      notifications,
      this.companyList.getCompaniesSelections(),
      this.companyGroup ? this.companyGroup.getLabelsSelected() : [],
      company
    );
    delete payload['credentials'];

    this.userService
      .updateUser(this.userInfo._id, payload)
      .pipe(take(1))
      .subscribe(() => {
        this.userService
          .getUser(this.userInfo._id)
          .pipe(take(1))
          .subscribe(({ data }) => {
            this.hideEditSection.emit(data[0]);
          });
      });
  }

  resetPassword() {
    this.alertService
      .confirm('Are you sure you want to reset the password?')
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((response) => {
        if (response) {
          this.userService
            .resetPassword(this.userInfo.profileInformation.email)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((response) => {
              this.alertService.alert(response);
            });
        }
      });
  }

  companies: any[];
  private getCompaniesList() {
    this.companyService.getCompanyInfo().subscribe((response) => {
      this.companies = response.data;
    });
  }

  existChanges = false;
  changeLabels(event) {
    this.change = event.change;
    this.difference = event.difference;
    this.containedCompanies = event.containedCompanies;
    this.onCountCompaniesAssociated();
    this.existChanges = true;
  }

  public onRegisterChanges($event) {
    this.existChanges = $event.changes;
    this.companiesAssociated = $event.items;
  }

  imageSrc;
  onFileChange(event) {
    const reader = new FileReader();

    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);

      reader.onload = () => {
        this.imageSrc = reader.result as string;
      };
    }
  }

  public onCountCompaniesAssociated() {
    setTimeout(() => {
      this.companiesAssociated = this.companyList.associatedCompaniesSelection.selected.length;
    }, 100);
  }

  public showStepTitle(stepper, idx: number): boolean {
    if (this.currentScreenSize == 'XLarge' || this.currentScreenSize == 'Large') {
      return true;
    } else if (this.currentScreenSize == 'Medium' || this.currentScreenSize == 'Small') {
      return idx == stepper.selectedIndex ? true : false;
    } else {
      return false;
    }
  }
}
