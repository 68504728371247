<div fxLayout="column" *ngIf="stringValue">
  <span class="body-2">{{ stringValue }}</span>
</div>

<div fxLayout="column" *ngIf="!stringValue && !isObjectInfoExpanded">
  <!-- h2 class="primary-text">{{ firstObjectLabel }}</h2>
  <span>{{ firstObjectValue }}</span -->
  <div
    class="obj-vis__expand-object body-2"
    fxLayout="row"
    fxLayoutAlign="start center"
    fxLayoutGap="10px"
    (click)="isObjectInfoExpanded = true"
    *ngIf="vctAttributes?.length > 1"
  >
    more... <mat-icon>arrow_drop_down</mat-icon>
  </div>
</div>

<div class="object-container" fxLayout="column" *ngIf="!stringValue && isObjectInfoExpanded">
  <section fxLayout="column" fxLayoutGap="16px">
    <div fxLayout="row" fxLayoutGap="10px" *ngFor="let attribute of vctAttributes">
      <app-primitive-element *ngIf="attribute.isPrimitive" [primitive]="attribute"></app-primitive-element>
      <app-object-element *ngIf="!attribute.isPrimitive" [obj]="attribute"></app-object-element>
    </div>
  </section>

  <div class="obj-vis__expand-object" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" (click)="isObjectInfoExpanded = false">
    less... <mat-icon>arrow_drop_up</mat-icon>
  </div>
</div>
