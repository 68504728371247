<section [id]="id">
  <mat-progress-bar color="primary" mode="indeterminate" *ngIf="spinnerService.visibility | async"></mat-progress-bar>

  <img [src]="img" id="logo" *ngIf="showImg" />

  <app-sys-label info="environment"></app-sys-label>
  <br />

  <form id="formId" [formGroup]="loginForm" (ngSubmit)="onSubmit()">
    <div *ngIf="!showImg">
      <label class="subtitle-4">Administration Module</label>
      <img [src]="img" id="logo-arrive" />
    </div>

    <div fxLayout="column" fxLayoutAlign="start center" class="input-form">
      <label class="subtitle-4">Username</label>

      <input matInput type="email" id="username" placeholder="Enter your username" formControlName="username" />
      <mat-error *ngIf="(loginForm.get('username').invalid && loginForm.get('username').touched) || loginForm.get('username').dirty">
        <b *ngIf="loginForm.get('username').errors?.required" class="text-danger">An username is required</b>
        <b *ngIf="loginForm.get('username').errors?.pattern" class="text-danger">Please provide a valid email address </b>
      </mat-error>
    </div>

    <div fxLayout="column" fxLayoutAlign="start center" class="input-form">
      <label class="subtitle-4">Password</label>

      <input matInput type="password" id="password" placeholder="Enter your password" formControlName="password" />
      <mat-error *ngIf="(loginForm.get('password').invalid && loginForm.get('password').touched) || loginForm.get('password').dirty">
        <b *ngIf="loginForm.get('password').errors?.required" class="text-danger">A Password is required</b>
      </mat-error>
    </div>

    <button mat-button [disabled]="loginForm.invalid">Login</button>
  </form>
</section>
