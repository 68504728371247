import { Component, OnInit } from '@angular/core';
import { LoginService } from '@app-main/services/login/login.service';
import { SpinnerService } from '@app-main/services/spinner/spinner.service';

import { environment } from '@environments/environment';
import LogRocket from 'logrocket';

@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.scss'],
})
export class MainLayoutComponent implements OnInit {
  companyName: any;
  companyLogo: any;
  username: any;
  scAccount: any;

  showDrawer = false;

  constructor(public spinnerService: SpinnerService, private loginService: LoginService) {}

  ngOnInit() {
    this.loginService.getUserInfo().subscribe((resp: any) => {
      this.companyName = resp.accountData.company.companyName;
      this.companyLogo = resp.accountData.account.logo;
      this.username = resp.accountData.username;
      this.scAccount = resp.accountData.starkcoreUsername;
    });

    if (environment.production) {
      LogRocket.init(environment.logrocketKey);
      LogRocket.identify(localStorage.getItem('email'), {
        name: localStorage.getItem('username'),
        email: localStorage.getItem('email'),
        // Add your own custom user variables here, ie:
        // subscriptionType: 'pro'
        account: localStorage.getItem('account'),
      });
    }
  }

  getClass(height: any): any {
    return {
      'max-height': `${height}px`,
      height: `100%`,
    };
  }
}
