import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '@app-core/authentication/authentication.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { LoginService } from '@app-main/services/login/login.service';
import { CompanyService } from '@app-main/services/company/company.service';
import { SpinnerService } from '@app-main/services/spinner/spinner.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { UtilitiesService } from '@app-main/services/utilities/utilities.service';
@Component({
  selector: 'app-login-layout',
  templateUrl: './login-layout.component.html',
  styleUrls: ['./login-layout.component.scss'],
})
export class LoginLayoutComponent implements OnDestroy, OnInit {
  private _ngUnsubscribe = new Subject<void>();

  loginForm = new FormGroup({
    username: new FormControl('', [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,20}$')]),
    password: new FormControl('', [Validators.required]),
  });

  id = 'login';
  formId = 'formId';
  img = 'assets/logos/LogoStarkCore.png';
  showImg = true;

  constructor(
    private router: Router,
    private authService: AuthenticationService,
    private loginService: LoginService,
    private companyService: CompanyService,
    public spinnerService: SpinnerService,
    private snackBar: MatSnackBar,
    private _scUtils: UtilitiesService
  ) {
    this._scUtils.changePageTitle(null);

    if (location.origin === 'https://ltladmin.arrivelogistics.com') {
      this.id = 'login-arrive';
      this.formId = 'formId-arrive';
      this.showImg = false;
      this.img = 'https://new-starkcore-tms-media-dev.s3.amazonaws.com/images/Artboard+1_x2.png';
    }
  }

  ngOnInit(): void {
    if (!this.authService.isTokenExpired()) {
      this.router.navigate(['/companies']);
    }
  }

  onSubmit() {
    this.authService
      .login(this.loginForm.value.username, this.loginForm.value.password)
      .pipe(takeUntil(this._ngUnsubscribe))
      .subscribe(
        (val: any) => {
          if (!val?.validLogin) {
            this.authService.setToken(val['id_token']);
            this.loginService
              .getUserInfo()
              .pipe(takeUntil(this._ngUnsubscribe))
              .subscribe((resp) => {
                const menuPermissions = resp.accountData.data.menuPermissions ?? [];

                localStorage.setItem('email', resp.accountData?.starkcoreUsername);
                localStorage.setItem('username', resp.accountData?.username);

                if (resp.accountData.userType === 'SuperAdmin' && menuPermissions.length > 0) {
                  localStorage.setItem('apiKey', resp.accountData.apiKey);
                  localStorage.setItem('arriveRole', resp.accountData.data.arriveRole);
                  localStorage.setItem('menuPermissions', JSON.stringify(menuPermissions));
                  this.companyService.getAccount(resp.accountData.apiKey).subscribe((response) => {
                    localStorage.setItem('account', response.data._id);
                    localStorage.setItem('logo', response.data.logo);
                    localStorage.setItem('accountName', response.data.name);
                    this.router.navigate(['/companies/']);
                    this.snackBar.dismiss();
                  });
                } else {
                  this.loginForm.reset();
                  this.authService.logout();
                  this.snackBar.open(`Error! User not allowed to login`, 'Close', {
                    duration: 3000,
                    horizontalPosition: 'center',
                    verticalPosition: 'bottom',
                  });
                }
              });
          } else {
            this.loginForm.reset();
            this.snackBar.open(`Error! User don't have access to this module`, 'Close', {
              duration: 3000,
              horizontalPosition: 'center',
              verticalPosition: 'bottom',
            });
          }
        },
        (error) => {
          this.loginForm.reset();
          this.snackBar.open(`Error! ${error.error.error_description}`, 'Close', {
            duration: 3000,
            horizontalPosition: 'center',
            verticalPosition: 'bottom',
          });
        }
      );
  }

  getClass(height: any): any {
    return {
      'max-height': `${height}px`,
      height: `100%`,
    };
  }

  ngOnDestroy() {
    this._ngUnsubscribe.next();
    this._ngUnsubscribe.complete();
  }
}
