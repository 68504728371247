import { Injectable } from '@angular/core';
import { HttpService } from '@app-core/http/http.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  account: string;

  constructor(private http: HttpService) {}

  getUserInfo(): Observable<any> {
    const resource = 'api/getAllUserData';
    return this.http.postStarkCore<any>(resource, null);
  }
}
