import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MainLayoutComponent } from './main-layout.component';
import { AuthGuard } from '@app-core/auth-guard/auth.guard';
import { ShowcaseComponent } from '@shared/starkCore-admin-theme/showcase/showcase.component';

const routes: Routes = [
  {
    path: '',
    component: MainLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'companies',
      },
      {
        path: 'accounts',
        loadChildren: () => import('../../main/accounts/accounts.module').then((m) => m.AccountsModule),
      },
      {
        path: 'companies',
        loadChildren: () => import('../../main/customer-information/customer-information.module').then((m) => m.CostumerInformationModule),
      },
      {
        path: 'carrierinformation',
        loadChildren: () => import('../../main/carrier-information/carrier-information.module').then((m) => m.CarrierInformationModule),
      },
      {
        path: 'reports',
        loadChildren: () => import('../../main/reports/reports.module').then((m) => m.ReportsModule),
      },
      {
        path: 'contracts',
        loadChildren: () => import('../../main/contracts/contracts.module').then((m) => m.ContractsModule),
      },
      {
        path: 'users',
        loadChildren: () => import('../../main/users/users.module').then((m) => m.UsersModule),
      },
      {
        path: 'labels',
        loadChildren: () => import('../../main/labels/labels.module').then((m) => m.LabelsModule),
      },
      {
        path: 'history',
        loadChildren: () => import('../../main/history/history.module').then((m) => m.HistoryModule),
      },
    ],
  },
  { path: 'showcase', component: ShowcaseComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class MainRoutingModule {}
