<section class="sc-dialog__wrapper" *ngIf="userInfo">
  <div class="sc-dialog__blank-space sc-dialog__blank-space--mid" (click)="onHideEdit()"></div>
  <div class="sc-dialog__container">
    <app-dialog-header [titlePreffix]="title" title="User Details" (onClose)="onHideEdit()"></app-dialog-header>

    <section class="sc-dialog-content">
      <mat-horizontal-stepper #stepper class="StepDetailUserComponent" color="accent" style="height: 100%">
        <ng-template matStepperIcon="edit">
          <mat-icon>check</mat-icon>
        </ng-template>

        <mat-step state="userInformation">
          <ng-template matStepLabel *ngIf="showStepTitle(stepper, 0)">
            <span class="subtitle-5">User</span>
            <h1 class="primary-text">Information</h1>
          </ng-template>
          <ng-container *ngTemplateOutlet="userInfoTemplate"></ng-container>
        </mat-step>

        <mat-step state="permissions" [aria-labelledby]="hideTabsForArrive ? 'disabled_Resi' : null">
          <ng-template matStepLabel *ngIf="showStepTitle(stepper, 1)">
            <span class="subtitle-5">User</span>
            <h1 class="primary-text">Permission</h1>
          </ng-template>
          <ng-container *ngTemplateOutlet="userPermission"></ng-container>
        </mat-step>

        <mat-step state="companies" [aria-labelledby]="hideTabsForArrive ? 'disabled_Resi' : null">
          <ng-template matStepLabel *ngIf="showStepTitle(stepper, 2)">
            <span class="subtitle-5">Associated</span>
            <h1 class="primary-text">Companies</h1>
          </ng-template>
          <ng-container *ngTemplateOutlet="companiesAssociate"></ng-container>
        </mat-step>
      </mat-horizontal-stepper>
    </section>

    <section class="sc-dialog-actions">
      <button mat-button b1 (click)="onSave()">SAVE</button>
    </section>
  </div>
</section>

<!-- section id="edit-users" >
  <div id="blank-space" (click)="onHideEdit()"></div>
  <section id="edit-section">
    <button class="CloseButtonEditUserDetail" mat-button b3 (click)="onHideEdit()">
      <mat-icon>close</mat-icon>
    </button>
    <h3 class="primary-text">
      <span class="accent-text">{{ title }}</span> &nbsp;User Details
    </h3>
    

    <div class="ControlsEditDetailUser">
      <button mat-button b1 (click)="onSave()">SAVE</button>
    </div>
  </section>
</section -->

<!-- User Info -->
<ng-template #userInfoTemplate>
  <form [formGroup]="userForm">
    <ng-container class="row" formGroupName="profileInformation">
      <div class="ProfileImagePanel">
        <!-- <div class="ProfileImageContainer">
          <img [src]="imageSrc" *ngIf="imageSrc" />
          <img src="https://www.w3schools.com/howto/img_avatar.png" *ngIf="userInfo.profileInformation.src.length > 1 && !imageSrc" />
          <label class="ProfileImageIcon" for="fileSelector"></label>
          <input type="file" hidden #fileSelector id="fileSelector" (change)="onFileChange($event)" />
        </div> -->
      </div>
      <div class="col-6 col-lg-6" style="display: inline-block">
        <p class="subtitle-5 primary-text">First Name*</p>
        <mat-form-field>
          <mat-icon class="material-icons-outlined" matPrefix>person_outline</mat-icon>
          <input formControlName="firstName" matInput type="text" placeholder="First Name" maxlength="30" />
          <mat-error *ngIf="userForm?.get('profileInformation').get('firstName')?.hasError('required')"> First name is required </mat-error>
        </mat-form-field>
      </div>

      <div class="col-6 col-lg-6" style="display: inline-block">
        <p class="subtitle-5 primary-text">Last Name*</p>
        <mat-form-field>
          <mat-icon class="material-icons-outlined" matPrefix>person_outline</mat-icon>
          <input formControlName="lastName" matInput type="text" placeholder="Last Name" maxlength="30" />
          <mat-error *ngIf="userForm?.get('profileInformation').get('lastName')?.hasError('required')"> Last name is required </mat-error>
        </mat-form-field>
      </div>

      <div class="col-6 col-lg-6" style="display: inline-block">
        <p class="subtitle-5 primary-text">Email</p>
        <mat-form-field>
          <mat-icon class="material-icons-outlined" matPrefix>email</mat-icon>
          <input formControlName="userName" matInput type="text" placeholder="Email" maxlength="50" />
          <mat-error *ngIf="userForm?.get('profileInformation')?.get('userName')?.hasError('required')"> Email is required </mat-error>
          <mat-error *ngIf="userForm?.get('profileInformation')?.get('userName')?.hasError('pattern')"> Must be a valid email</mat-error>
        </mat-form-field>
      </div>

      <div id="reset-password">
        <button class="reset-password" mat-button b2 small (click)="resetPassword()" *ngIf="title === 'Edit'">RESET PASSWORD</button>
      </div>
    </ng-container>
  </form>
</ng-template>

<!-- User Permission -->
<ng-template #userPermission>
  <form [formGroup]="userForm">
    <ng-container class="row" formGroupName="permissions">
      <div class="row mt-5">
        <div class="col-5">
          <mat-label>
            <h1>Office</h1>
          </mat-label>
          <mat-select disableOptionCentering formControlName="office" (valueChange)="onEnableRol($event)">
            <mat-option *ngFor="let office of offices" [value]="office.name">
              {{ office.name }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="userForm?.get('permissions').get('office')?.hasError('required')">Office is required</mat-error>
        </div>

        <div class="col"></div>
        <div class="col-5">
          <mat-label>
            <h1>Role</h1>
          </mat-label>
          <mat-select class="titlecase" disableOptionCentering placeholder="" formControlName="role" (valueChange)="onChangeRole($event)">
            <mat-option *ngFor="let role of roles" [value]="role.value">
              {{ role.viewValue | titlecase }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="userForm?.get('permissions').get('role')?.hasError('required')">Role is required</mat-error>
        </div>
      </div>
      <div class="row mt-5">
        <div class="col-5">
          <mat-label>
            <h1>Sub Role</h1>
          </mat-label>
          <mat-select disableOptionCentering formControlName="subRole">
            <mat-option *ngFor="let subRole of subRoles" [value]="subRole.value">
              {{ subRole.viewValue | titlecase }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="userForm?.get('permissions').get('subRole')?.hasError('required')">Sub Role is required</mat-error>
        </div>
        <div class="col"></div>
        <div class="col-5">
          <mat-label *ngIf="!hiddeBossSelect">
            <h1>Direct Manager</h1>
          </mat-label>
          <mat-select
            disableOptionCentering
            [matTooltip]="bosses?.length === 0 ? 'There isn’t an Direct Manager available' : ''"
            formControlName="boss"
            *ngIf="!hiddeBossSelect"
          >
            <mat-option *ngFor="let boss of bosses" [value]="boss._id">
              {{ boss.name | titlecase }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="bosses?.length !== 0 && userForm?.get('permissions').get('boss')?.hasError('required')"
            >Direct Manager is required</mat-error
          >
        </div>
      </div>
    </ng-container>
  </form>
</ng-template>

<!-- Companies -->
<ng-template #companiesAssociate>
  <mat-accordion>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          <h1 class="primary-text">Select Associated Companies</h1>
          <span class="numberSelected"> +{{ companiesAssociated }} </span>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <app-company-list
        [containedCompanies]="containedCompanies"
        [change]="change"
        [difference]="difference"
        [_readonly]="false"
        (eventEmmiter)="onRegisterChanges($event)"
        [data]="userInfo.companyRestrictions"
        [isCustomer]="userForm?.get('permissions').get('userType')?.value === 'Customer'"
        #companyList
      >
      </app-company-list>
    </mat-expansion-panel>

    <mat-expansion-panel
      (opened)="companyGroup.showLabelTemplate = false"
      *ngIf="userForm?.get('permissions').get('userType')?.value !== 'customer'"
    >
      <mat-expansion-panel-header>
        <mat-panel-title>
          <div class="titleMatExpansion">
            <h1 class="primary-text">Company Groups</h1>
            <button mat-button icon (click)="$event.stopPropagation(); companyGroup.showAddLabelTemplate()">
              <mat-icon>add</mat-icon>
            </button>
          </div>
          <div id="title">
            <span class="numberSelected"> +{{ companyGroup.numberContainedCompanies }} </span>
          </div>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <app-company-group ($changeLabels)="changeLabels($event)" [_readonly]="false" [data]="userInfo.labels" #companyGroup>
      </app-company-group>
    </mat-expansion-panel>
  </mat-accordion>
</ng-template>
