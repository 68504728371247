export const ACCOUNT_ADMIN = {
  value: 'AccountAdministrator',
  apiValue: 'accountAdministrator',
  viewValue: 'Account Administrator',
};

export const SUPER_ADMIN = {
  value: 'AccountUser',
  viewValue: 'Account User',
  apiValue: 'superAdmin',
};
export const CUSTOMER = {
  value: 'customer',
  apiValue: 'customer',
  viewValue: 'Customer',
};
