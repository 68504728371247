<mat-card class="mat-elevation-z2" fxLayout="row">
  <section fxLayout="column" [fxFlex]="intWideCard || showSelected ? '50' : '100'" [fxHide]="showSelected">
    <mat-card-header>
      <mat-card-title>
        <h2 class="subtitle-5">Companies ({{ associatedCompaniesDS.data?.length }})</h2>
      </mat-card-title>
    </mat-card-header>

    <mat-card-content id="company-list-container">
      <mat-form-field small>
        <mat-icon matPrefix>search</mat-icon>
        <input matInput placeholder="Search" (keyup)="filterAssociatedCompanies($event)" />
      </mat-form-field>
      <table class="company-table" mat-table [dataSource]="associatedCompaniesDS">
        <ng-container matColumnDef="company">
          <th mat-header-cell *matHeaderCellDef>
            <span class="body-2 greyMedium-text" [fxShow]="intWideCard">Company</span>
            <span fxLayout="row" fxLayoutAlign="start center" class="body-2 greyMedium-text" *ngIf="!_readonly" [fxShow]="!intWideCard">
              <mat-icon *ngIf="!showSelected" (click)="showSelected = true">visibility</mat-icon>
              <mat-icon *ngIf="showSelected" (click)="showSelected = false">visibility_off</mat-icon>
              &nbsp; Selected ({{ associatedCompaniesSelection?.selected.length }})
            </span>
          </th>
          <td mat-cell *matCellDef="let element">
            <div class="company">
              <span class="company-logo">
                <img [src]="element.logo" onerror="this.src = 'assets/icons/icon_company.svg'" />
              </span>
              <h2 class="primary-text">{{ element.name }}</h2>
            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="select">
          <th mat-header-cell *matHeaderCellDef>
            <mat-checkbox
              light
              *ngIf="!_readonly && !isCustomer"
              (change)="$event ? masterToggle() : null; emitChange()"
              [checked]="associatedCompaniesSelection.hasValue() && isAllSelected()"
              [indeterminate]="associatedCompaniesSelection.hasValue() && !isAllSelected()"
            >
            </mat-checkbox>
          </th>
          <td mat-cell *matCellDef="let element">
            <mat-checkbox
              [disabled]="lodash.includes(containedCompanies, element.name)"
              light
              *ngIf="!_readonly"
              (click)="$event.stopPropagation()"
              (change)="$event ? associatedCompaniesSelection.toggle(element.name) : null; emitChange()"
              [checked]="associatedCompaniesSelection.isSelected(element.name)"
            >
            </mat-checkbox>
          </td> </ng-container
        >"
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr
          [matTooltipDisabled]="!lodash.includes(containedCompanies, row.name)"
          matTooltip="This company is locked because it's already contained in a label"
          (click)="lodash.includes(containedCompanies, row.name) ? null : associatedCompaniesSelection.toggle(row.name)"
          mat-row
          *matRowDef="let row; columns: displayedColumns"
        ></tr>
      </table>
    </mat-card-content>

    <mat-card-actions>
      <mat-paginator
        id="company-list-paginator"
        paginator
        [pageSize]="8"
        [pageSizeOptions]="[5, 10, 25]"
        (page)="onPage()"
        showFirstLastButtons
      ></mat-paginator>
    </mat-card-actions>
  </section>

  <div class="editing-space" [fxShow]="intWideCard"></div>

  <section
    class="editing-section"
    fxLayout="column"
    [fxFlex]="intWideCard ? '50' : showSelected ? '100' : '0'"
    [fxShow]="intWideCard || showSelected"
  >
    <mat-card-header>
      <mat-card-title fxLayout="row" fxLayoutAlign="start center">
        <mat-icon *ngIf="showSelected" (click)="showSelected = false">visibility_off</mat-icon>
        &nbsp; Selected<span *ngIf="showSelected">&nbsp;companies</span> ({{ associatedCompaniesSelection?.selected.length }})
      </mat-card-title>
    </mat-card-header>

    <mat-form-field small>
      <mat-icon matPrefix>filter_list</mat-icon>
      <input matInput placeholder="Filter" [(ngModel)]="chipFilterText" />
    </mat-form-field>
    <mat-card-content class="sc-scrollable" id="selected-companies-chips">
      <mat-chip-list>
        <mat-chip *ngFor="let company of associatedCompaniesSelection.selected | filterVctStr : chipFilterText" class="grey-veryLight">
          <span class="subtitle-3 primary-text">{{ company }}</span>
          <mat-icon
            *ngIf="!lodash.includes(containedCompanies, company)"
            (click)="associatedCompaniesSelection.toggle(company)"
            class="primary-text"
            matChipRemove
          >
            close</mat-icon
          >
        </mat-chip>
      </mat-chip-list>
    </mat-card-content>
  </section>
</mat-card>

<!-- section id="user-companies" *ngIf="!state">
  <ng-container *ngTemplateOutlet="companies"></ng-container>
</section>

<section id="user-companies" *ngIf="state">
  <ng-container *ngTemplateOutlet="listMobile"></ng-container>
</section>

<ng-template #companies>
  <div id="associatedCompanies">
    <div *ngIf="!_readonly" class="user-selected-companies-container">
      <div class="selected-companies-title">
        <h2 class="primary-text">Selected Companies</h2>
        <span class="numberSelected" *ngIf="!associatedCompaniesSelection.isEmpty()">
          +{{ associatedCompaniesSelection.selected.length }}
        </span>
      </div>

      <div class="chip-filter" *ngIf="!associatedCompaniesSelection.isEmpty()">
        <mat-form-field>
          <mat-icon matPrefix>filter_list</mat-icon>
          <input matInput placeholder="Filter" [(ngModel)]="chipFilterText" />
        </mat-form-field>
      </div>

      <div class="chip-list-container" *ngIf="!associatedCompaniesSelection.isEmpty()">
        <mat-chip-list>
          <mat-chip *ngFor="let company of associatedCompaniesSelection.selected | filterVctStr: chipFilterText" class="grey-veryLight">
            <span class="subtitle-3 primary-text">{{ company }}</span>
            <mat-icon
              *ngIf="!lodash.includes(containedCompanies, company)"
              (click)="associatedCompaniesSelection.toggle(company)"
              class="primary-text"
              matChipRemove
            >
              close</mat-icon
            >
          </mat-chip>
        </mat-chip-list>
      </div>
    </div>

    <div id="table">
      <mat-form-field small>
        <mat-icon matPrefix>search</mat-icon>
        <input matInput placeholder="Search" (keyup)="filterAssociatedCompanies($event)" />
      </mat-form-field>
      <table mat-table [dataSource]="associatedCompaniesDS">
        <ng-container matColumnDef="company">
          <th mat-header-cell *matHeaderCellDef><span class="body-2 greyMedium-text">Company</span></th>
          <td mat-cell *matCellDef="let element">
            <div class="company">
              <span class="company-logo">
                <img [src]="element.logo" onerror="this.src = 'assets/icons/icon_company.svg'" />
              </span>
              <h2 class="primary-text">{{ element.name }}</h2>
            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="select">
          <th mat-header-cell *matHeaderCellDef>
            <mat-checkbox
              light
              *ngIf="!_readonly && !isCustomer"
              (change)="$event ? masterToggle() : null; emitChange()"
              [checked]="associatedCompaniesSelection.hasValue() && isAllSelected()"
              [indeterminate]="associatedCompaniesSelection.hasValue() && !isAllSelected()"
            >
            </mat-checkbox>
          </th>
          <td mat-cell *matCellDef="let element">
            <mat-checkbox
              [disabled]="lodash.includes(containedCompanies, element.name)"
              light
              *ngIf="!_readonly"
              (click)="$event.stopPropagation()"
              (change)="$event ? associatedCompaniesSelection.toggle(element.name) : null; emitChange()"
              [checked]="associatedCompaniesSelection.isSelected(element.name)"
            >
            </mat-checkbox>
          </td> </ng-container
        >"
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr
          [matTooltipDisabled]="!lodash.includes(containedCompanies, row.name)"
          matTooltip="This company is locked because it's already contained in a label"
          (click)="lodash.includes(containedCompanies, row.name) ? null : associatedCompaniesSelection.toggle(row.name)"
          mat-row
          *matRowDef="let row; columns: displayedColumns"
        ></tr>
      </table>
      <mat-paginator paginator [pageSize]="8" [pageSizeOptions]="[5, 10, 25]" showFirstLastButtons> </mat-paginator>
    </div>
  </div>
</ng-template>

<ng-template #listMobile>
  <div id="associatedCompanies-mobile" [ngClass]="{ 'one-grid': _readonly }">
    <div id="table">
      <mat-form-field small>
        <mat-icon matPrefix>search</mat-icon>
        <input matInput placeholder="Search" (keyup)="filterAssociatedCompanies($event)" />
      </mat-form-field>
      <table mat-table [dataSource]="associatedCompaniesDS">
        <ng-container matColumnDef="company">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let element">
            <div class="company">
              <span class="company-logo">
                <img [src]="element.logo" onerror="this.src = 'assets/icons/icon_company.svg'" />
              </span>
              <h2 class="primary-text">{{ element.name }}</h2>
            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="select">
          <th mat-header-cell *matHeaderCellDef>
            <mat-checkbox
              light
              *ngIf="!_readonly && !isCustomer"
              (change)="$event ? masterToggle() : null; emitChange()"
              [checked]="associatedCompaniesSelection.hasValue() && isAllSelected()"
              [indeterminate]="associatedCompaniesSelection.hasValue() && !isAllSelected()"
            >
            </mat-checkbox>
          </th>
          <td mat-cell *matCellDef="let element">
            <mat-checkbox
              [disabled]="lodash.includes(containedCompanies, element.name)"
              light
              *ngIf="!_readonly"
              (click)="$event.stopPropagation()"
              (change)="$event ? associatedCompaniesSelection.toggle(element.name) : null; emitChange()"
              [checked]="associatedCompaniesSelection.isSelected(element.name)"
            >
            </mat-checkbox>
          </td> </ng-container
        >"
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr
          [matTooltipDisabled]="!lodash.includes(containedCompanies, row.name)"
          matTooltip="This company is locked because it's already contained in a label"
          (click)="lodash.includes(containedCompanies, row.name) ? null : associatedCompaniesSelection.toggle(row.name)"
          mat-row
          *matRowDef="let row; columns: displayedColumns"
        ></tr>
      </table>
    </div>
    <div id="selected" *ngIf="!_readonly">
      <div>
        <div id="title">
          <h2 class="primary-text">Selected Companies</h2>
          <span class="numberSelected" *ngIf="!associatedCompaniesSelection.isEmpty()">
            +{{ associatedCompaniesSelection.selected.length }}
          </span>
        </div>
        <div>
          <mat-chip-list>
            <mat-chip *ngFor="let company of associatedCompaniesSelection.selected" class="grey-veryLight">
              <span class="subtitle-3 primary-text">{{ company }}</span>
              <mat-icon
                *ngIf="!lodash.includes(containedCompanies, company)"
                (click)="associatedCompaniesSelection.toggle(company)"
                class="primary-text"
                matChipRemove
              >
                close
              </mat-icon>
            </mat-chip>
          </mat-chip-list>
        </div>
      </div>
    </div>
  </div>
</ng-template -->
