import { Injectable } from '@angular/core';
import { HttpService } from '@app-core/http/http.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class HistoryService {
  constructor(private http: HttpService) {}

  getHistoryItems(resource = null): Observable<any> {
    const route = resource ? resource : `histories?page=${1}&pageSize=${10}`;
    return this.http.get<any>(route, null);
  }
}
