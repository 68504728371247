import { Injectable } from '@angular/core';
import { HttpService } from '@app-core/http/http.service';
import { Observable } from 'rxjs';
import { AccountService } from '../company/account.service';

const filterFields = ['name', 'companyCode'];

@Injectable({
  providedIn: 'root',
})
export class CompanyService {
  constructor(private http: HttpService, private accountService: AccountService) {}

  getCompanyInfo(): Observable<any> {
    const resource = 'company';
    return this.http.get<any>(resource, null);
  }

  getCompanyData(id): Observable<any> {
    const resource = `company/${id}`;
    return this.http.get<any>(resource, null);
  }

  getCompanyLabels(id): Observable<any> {
    const resource = `company/get-labels/${id}`;
    return this.http.get<any>(resource, null);
  }

  updateCompanyData(id, body: any): Observable<any> {
    const resource = `company/${id}`;
    return this.http.patch<any>(resource, body, { observe: 'response' });
  }

  updateShipments(body: any): Observable<any> {
    const resource = 'shipment';
    return this.http.put<any>(resource, body, { observe: 'response' });
  }

  updateCompanyRestrictions(body: any): Observable<any> {
    const resource = 'user/company-restrictions';
    return this.http.post<any>(resource, body, { observe: 'response' });
  }

  getAccount(apikey): Observable<any> {
    const resource = `account/${apikey}`;
    return this.http.get<any>(resource, apikey);
  }

  createNewCompany(body: any): Observable<any> {
    const resource = 'company';
    return this.http.post<any>(resource, body, { observe: 'response' });
  }

  // cuando se cambia el uplift de la compañia, este metodo actualiza todos los uplift en los contratos
  updateUpliftContracts(body: any): Observable<any> {
    const resource = 'contracts/uplift';
    return this.http.put<any>(resource, body, { observe: 'response' });
  }

  getUserColumns(): string[] {
    return this.accountService.isArrive
      ? ['checkbox', 'username', 'role', 'subRole', 'userRole', 'email', 'actions']
      : ['checkbox', 'username', 'userRole', 'email', 'actions'];
  }

  addSelectFilter(list: any[], fields: string[]): any[] {
    return list.map((item) => {
      const filterObj = {};
      fields.forEach((field) => {
        if (item[field]) filterObj[field] = item[field].trim();
      });
      return {
        ...item,
        filter: Object.values(filterObj).join(','),
      };
    });
  }

  withCodeFiltering(list: any[]): any[] {
    return this.addSelectFilter(list, filterFields);
  }

  getFilterValue(item: any, fields: string[]): any {
    const filterObj = {};
    fields.forEach((field) => {
      if (item[field]) filterObj[field] = item[field].trim();
    });
    return Object.values(filterObj).join(',').toLowerCase();
  }

  getCodeFilterValue(item: any): any {
    return this.getFilterValue(item, filterFields);
  }
}
