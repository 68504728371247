import { Component, EventEmitter, HostListener, Input, OnChanges, Output, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-attribute-changes-detail',
  templateUrl: './attribute-changes-detail.component.html',
  styleUrls: ['./attribute-changes-detail.component.scss'],
})
export class AttributeChangesDetailComponent implements OnChanges {
  //public displayedColumns = ['field', 'previewsValue', 'newValue'];
  public displayedColumns: string[] = ['field', 'previewsValue', 'newValue'];

  private _attribute: any;
  @Output() attributeChange = new EventEmitter();
  set attribute(val: any) {
    this._attribute = val;
    this.attributeChange.emit(this._attribute);
  }
  @Input()
  get attribute(): any {
    return this._attribute;
  }

  @Output() onClose = new EventEmitter<any>();
  public dataSource = new MatTableDataSource([]);
  public totalChanges = 0;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  public whyyyyyy = [];

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (event.key == 'Escape') {
      this.attribute = null;
    }
  }

  ngOnChanges(): void {
    this.onPagination();
  }

  public makeDate(date): Date {
    return new Date(date);
  }

  public onPagination(): void {
    const start = this.paginator.pageIndex * this.paginator.pageSize;
    const end = (this.paginator.pageIndex + 1) * this.paginator.pageSize;
    this.dataSource.data = this.attribute ? this.attribute.changes?.slice(start, end) : [];

    this.paginator.length = this.attribute ? this.attribute.changes?.length : 0;
  }
}
