import { Injectable } from '@angular/core';
import { HttpService } from '@app-core/http/http.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LabelService {
  account: string;

  constructor(private http: HttpService) {}

  getLabels(params): Observable<any> {
    const resource = 'labels/get-all-labels';
    return this.http.post(resource, params, { observe: 'body' });
  }

  createLabel(payload): Observable<any> {
    const resource = 'labels';
    return this.http.post(resource, payload);
  }

  getOffices(): Observable<any> {
    const resource = `labels/get-offices/${localStorage.getItem('account')}/account`;
    return this.http.get<any>(resource, null);
  }

  addCompaniesToLabels(body) {
    const resource = 'labels/add-companies';
    return this.http.post(resource, body);
  }

  deleteCompaniesFromLabels(body) {
    const resource = 'labels/remove-companies';
    return this.http.post(resource, body);
  }

  validateUsers(body): Observable<any> {
    const resource = 'labels/validateUsers';
    return this.http.post(resource, body);
  }

  deleteLabels(labels): Observable<any> {
    const resource = 'labels/delete';
    return this.http.delete(resource, labels);
  }

  updateLabel(labelId, label) {
    const resource = `labels/${labelId}`;
    return this.http.put(resource, label);
  }
}
