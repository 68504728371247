import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root',
})
export class UtilitiesService {
  private envPreffix: string;

  constructor() {
    if (environment.production) {
      this.envPreffix = undefined;
    } else if (window.location.href.indexOf('localhost') > -1) {
      this.envPreffix = 'loc';
    } else if (environment.env === 'staging') {
      this.envPreffix = 'stg';
    } else if (environment.env === 'dev') {
      this.envPreffix = 'dev';
    } else if (environment.env === 'uat') {
      this.envPreffix = 'uat';
    } else if (environment.env === 'hmr') {
      this.envPreffix = 'hmr';
    } else {
      this.envPreffix = undefined;
    }
  }

  public getEnvironmentPreffix(): string {
    return this.envPreffix;
  }

  public changePageTitle(title: string): void {
    let newTitle = title ? title + ' - Administrative Module' : 'Administrative Module';

    if (this.envPreffix) {
      newTitle = newTitle + ' ' + '(' + this.envPreffix + ')';
    }

    document.title = newTitle;
  }

  public isThisAnObject(susObj): boolean {
    return typeof this.getSusObjAsObj(susObj) === 'object';
  }

  public getSusObjAsObj(susObj): boolean {
    try {
      if (typeof susObj === 'object') {
        return susObj;
      } else if (typeof JSON.parse(susObj) === 'object') {
        return JSON.parse(susObj);
      } else {
        return undefined;
      }
    } catch (error) {
      return undefined;
    }
  }
}
