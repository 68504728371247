import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AlertService } from '@app-main/services/alerts/alert.service';
import { UserService } from '@app-main/services/user/user.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as _ from 'lodash';

@Component({
  selector: 'app-edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.scss'],
})
export class EditUserComponent implements OnInit, OnDestroy {
  @Input() title: string;
  @Input() companyName: string;
  @Input() companyList;
  @Input() userId;
  @Output() hideEditSection = new EventEmitter<void>();
  @Output() $saveUser = new EventEmitter<void>();

  ngUnsubscribe = new Subject<void>();
  userForm: FormGroup;
  permissions: any[] = [];
  companyRestrictions: any[] = [];
  regex = /([A-Z])/;
  user: any;
  oldRestrictions: any;
  userPermissions: any;
  windows: boolean;
  emailRegex = '^[A-z0-9._%+-]+@[A-z0-9.-]+\\.[A-z]{2,4}$';

  constructor(private alertService: AlertService, private userService: UserService, private formBuilder: FormBuilder) {
    this.windows = window.innerWidth <= 960 ? true : false;

    this.userForm = this.formBuilder.group({
      mainData: this.formBuilder.group({
        profileInformation: this.formBuilder.group({
          firstName: ['', Validators.required],
          lastName: ['', Validators.required],
          email: ['', [Validators.required, Validators.pattern(this.emailRegex)]],
          occupation: ['', Validators.required],
          phone: ['', [Validators.required, Validators.minLength(10)]],
        }),
        password: this.formBuilder.group({
          password: [''],
          confirmPassword: [''],
        }),
      }),
      authorization: this.formBuilder.group({
        role: ['', Validators.required],
        permission: [{ value: '', disabled: true }, [Validators.required, this.validateRole.bind(this)]],
      }),
      notifications: this.formBuilder.group({
        desktop: [false],
        email: [false],
      }),
    });
  }

  ngOnInit(): void {
    this.getUser();
    this.userForm.get('authorization.role').valueChanges.subscribe((value) => {
      this.userForm.get('authorization.permission').setValue(value);
    });
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  save() {
    if (this.userForm.invalid) {
      this.alertService.alert('Some information might be missing!');
      this.userForm.markAllAsTouched();
    } else {
      const payload = this.setPayloadEdit();
      this.userService
        .updateUser(this.userId, payload)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(() => {
          this.$saveUser.emit();
        });
    }
  }

  create() {
    if (this.userForm.invalid) {
      this.alertService.alert('Some information might be missing!');
      this.userForm.markAllAsTouched();
    } else {
      const payload = this.setPayloadCreate();
      this.userService
        .createUser(payload)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(() => {
          this.$saveUser.emit();
        });
    }
  }

  setPayloadCreate() {
    const payload = {
      aditionals: {
        showCarrierRates: false,
        readOnly: this.userForm.get('authorization.permission').value.userRole === 'ReadOnly' ? true : false,
        canOverrideAdmin: false,
        subrole: this.userForm.get('authorization.permission').value.userRole,
      },
      agreement: {
        conscent: false,
        documents: {
          licenseAgreement: 'https://docs.google.com/document/d/1afx124hipyye_pnc0jf7ofogt2etdoepxogid0ekrru/edit?usp=sharing',
        },
      },
      company: this.userForm.get('company').value._id,
      companyName: this.userForm.get('company').value.name,
      companyRestrictions: this.companyRestrictions,
      credentials: {
        username: this.userForm.get('mainData.profileInformation.email').value,
      },
      customStarkCore: {
        dashboardReports: [],
        notifications: {
          desktop: this.userForm.get('notifications.desktop').value,
        },
        ownPricingFilter: this.userForm.get('notifications.desktop').value,
      },
      emailNotifications: {
        shipment: this.userForm.get('notifications.email').value,
      },
      isCustomer: this.userForm.get('authorization.role').value === 'customer' ? true : false,
      isSuperAdmin: this.userForm.get('authorization.role').value !== 'customer' ? true : false,
      password: this.userForm.get('mainData.password.password').value,
      profileInformation: { ...this.userForm.get('mainData.profileInformation').value },
      sidebar: this.userForm.get('authorization.permission').value.sidebar,
      userRole: this.userForm.get('authorization.permission').value.userRole,
      username: this.userForm.get('mainData.profileInformation.email').value,
    };
    return payload;
  }

  setPayloadEdit() {
    const payload = {
      aditionals: {
        showCarrierRates: this.userForm.get('authorization.role').value !== 'customer' ? true : false,
        readOnly: false,
        canOverrideAdmin: false,
        subrole: this.userForm.get('authorization.permission').value,
      },
      agreement: {
        conscent: false,
        documents: {
          licenseAgreement: 'https://docs.google.com/document/d/1afx124hipyye_pnc0jf7ofogt2etdoepxogid0ekrru/edit?usp=sharing',
        },
      },
      company: this.user.company,
      companyName: this.user.companyName,
      companyRestrictions: this.companyRestrictions,
      customStarkCore: {
        dashboardReports: [],
        notifications: {
          desktop: this.userForm.get('notifications.desktop').value,
        },
        ownPricingFilter: this.userForm.get('notifications.desktop').value,
      },
      emailNotifications: {
        shipment: this.userForm.get('notifications.email').value,
      },
      isCustomer: this.userForm.get('authorization.role').value === 'customer' ? true : false,
      isSuperAdmin: this.userForm.get('authorization.role').value !== 'customer' ? true : false,
      profileInformation: { ...(this.userForm.get('mainData.profileInformation') as FormGroup).getRawValue() },
      userRole: this.userForm.get('authorization.role').value,
    };
    if (
      this.userForm.get('authorization.role').value === 'customer' &&
      this.userForm.get('authorization.role').value !== this.user.userRole
    ) {
      payload.companyRestrictions = [];
    }
    return payload;
  }

  getUser() {
    if (this.title === 'Create') {
      this.companyRestrictions = [this.companyName];
      this.userForm.get('mainData.password').setValidators(this.confirmPassword);
      this.userForm.get('mainData.password.password').setValidators(Validators.required);
      this.userForm.get('mainData.password.confirmPassword').setValidators(Validators.required);
    } else if (this.title === 'Edit') {
      this.userService
        .getUser(this.userId)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((response) => {
          this.user = response.data[0];
          this.oldRestrictions = this.user.companyRestrictions;
          this.companyRestrictions = this.oldRestrictions;
          this.populateForm();
        });
    }
  }

  populateForm() {
    this.userForm.patchValue({
      mainData: {
        profileInformation: this.user.profileInformation,
      },
      authorization: {
        role: this.user.userRole,
        permission: this.user.userRole,
      },
      company: this.companyList.filter((company) => {
        return company.name === this.user.companyName;
      })[0],
      notifications: {
        desktop: this.user.customStarkCore.notifications.desktop,
        email: this.user.emailNotifications.shipment,
      },
    });

    this.userForm.get('mainData.profileInformation.email').disable();
  }

  /** Main Data */
  registerCompanies(checked) {
    if (checked) {
      this.companyRestrictions = this.companyList.map((element) => {
        return element.name;
      });
    } else if (this.title === 'Create') {
      this.companyRestrictions = [this.companyName];
    } else if (this.title === 'Edit') {
      this.companyRestrictions = this.oldRestrictions;
    }
  }

  /** Authorization & Company */

  resetPassword() {
    this.alertService
      .confirm('Are you sure you want to reset the password?')
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((response) => {
        if (response) {
          this.userService
            .resetPassword(this.user.profileInformation.email)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((response) => {
              this.alertService.alert(response);
            });
        }
      });
  }

  /** General Methods */
  confirmPassword(group: FormGroup) {
    if (group.get('password').value !== group.get('confirmPassword').value && group.get('password').value !== '') {
      group.get('confirmPassword').setErrors({ password: true });
      return { password: true };
    }
  }

  validateRole(control: FormControl) {
    if (this.permissions.length === 0) {
      return { role: true };
    } else if (control.value === '') {
      return { required: true };
    }
    return null;
  }

  permissionDisplay(role) {
    if (role === undefined || role === null) {
      return '';
    } else {
      return role.replace(/([A-Z])/g, ' $1').trim();
    }
  }

  getClass(height: any): any {
    return {
      'max-height': `${height}px`,
      height: `100%`,
    };
  }

  hideEdit() {
    if (this.userForm.dirty) {
      this.alertService
        .confirm('Do you want to continue without saving?')
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((resp) => {
          if (resp) {
            this.hideEditSection.emit();
          }
        });
    } else {
      this.hideEditSection.emit();
    }
  }
}
