import { SelectionModel } from '@angular/cdk/collections';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { CompanyService } from '@app-main/services/company/company.service';
import { LabelService } from '@app-main/services/labels/label.service';
import * as _ from 'lodash';
import { forkJoin } from 'rxjs';
import { Subject } from 'rxjs';
import { debounceTime, map, take, takeUntil } from 'rxjs/operators';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { displayNameMap } from '@shared/models/Breakpoint.model';

@Component({
  selector: 'app-company-group',
  templateUrl: './company-group.component.html',
  styleUrls: ['./company-group.component.scss'],
})
export class CompanyGroupComponent implements OnInit, OnDestroy {
  public currentScreenSize: string;
  _ngUnsubscribe = new Subject<void>();

  @Input()
  readonly _readonly = false;

  @Input()
  readonly data: any;

  @Input() state = false;

  @Output() $changeLabels = new EventEmitter<any>();

  numberContainedCompanies = 0;

  labels: MatTableDataSource<any>;
  labelsCount: number;
  labelListSelection = new LabelListSelection();
  labelListFilter: FormControl = new FormControl('');
  containedCompanies: any[] = [];

  newGroupDS: MatTableDataSource<any>;
  newGroupSelection = new SelectionModel<any>(true, []);
  newLabelName: FormControl = new FormControl('', Validators.required);
  enableAddNewLabel = false;
  newLabelFilter: string;
  filter = '';
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild('newLabelPaginator', { static: false }) newLabelPaginator: MatPaginator;
  displayedColumns = ['company', 'select'];
  previousSelection = [];
  account: string;

  chipFilterText = '';

  constructor(
    private labelService: LabelService,
    private companyService: CompanyService,
    private snackBar: MatSnackBar,
    private _breakpointObserver: BreakpointObserver
  ) {
    this.account = localStorage.getItem('account');
    this.labels = new MatTableDataSource([]);
    this.newGroupDS = new MatTableDataSource([]);

    this._breakpointObserver
      .observe([Breakpoints.XSmall, Breakpoints.Small, Breakpoints.Medium, Breakpoints.Large, Breakpoints.XLarge])
      .pipe(takeUntil(this._ngUnsubscribe))
      .subscribe((result) => {
        for (const query of Object.keys(result.breakpoints)) {
          if (result.breakpoints[query]) {
            this.currentScreenSize = displayNameMap.get(query);
          }
        }
      });
  }

  ngOnInit() {
    const params = {
      limit: 8,
      skip: 0,
      account: this.account,
    };
    this.getLabels(params);
    this.getCompaniesList();
    /**
     * Filter Predicates
     */

    this.newGroupDS.filterPredicate = (data: any, filter: string) => {
      return data.name.trim().toLowerCase().includes(filter);
    };
    /**
     * Label List Filter
     */
    this.labelListFilter.valueChanges.pipe(debounceTime(500)).subscribe((value) => {
      if (this._readonly) {
        this.filter = value;
        this.paginReadOnly();
      } else {
        const params = {
          skip: 0,
          limit: this.paginator.pageSize,
          name: value,
          account: this.account,
        };
        this.paginator.pageIndex = 0;
        this.getLabels(params);
      }
    });
    this.labelListSelection.$change.subscribe((change) => {
      this.containedCompanies = _.union(...this.labelListSelection.selected.map((label) => label.companies.map((company) => company.name)));
      this.numberContainedCompanies = this.containedCompanies.length;

      let difference = [];
      if (change.removed) {
        difference = _.difference(
          change.removed.companies.map((element) => {
            return element.name;
          }),
          this.containedCompanies
        );
      }
      const changeLabel = {
        change,
        difference,
        containedCompanies: this.containedCompanies,
      };
      this.$changeLabels.emit(changeLabel);
    });
  }

  ngOnDestroy() {
    this._ngUnsubscribe.next();
    this._ngUnsubscribe.complete();
  }

  getCompaniesList() {
    this.companyService
      .getCompanyInfo()
      .pipe(
        take(1),
        map((resp) => {
          this.snackBar.dismiss();
          return resp;
        })
      )
      .subscribe((response) => {
        this.newGroupDS.data = response.data;
        this.snackBar.dismiss();
      });
  }

  getLabels(params) {
    const req = this._readonly ? this.labelService.getLabels({ account: this.account, skip: 0 }) : this.labelService.getLabels(params);
    forkJoin({
      labels: req,
    })
      .pipe(
        take(1),
        map((resp) => {
          this.snackBar.dismiss();
          return resp;
        })
      )
      .subscribe((response) => {
        this.snackBar.dismiss();
        if (this._readonly) {
          const map = this.data?.map((item) => item.id);
          this.previousSelection = response.labels.data.filter((item) => map?.includes(item._id));
          this.labels.data = this.previousSelection.slice(0, params.limit);
          this.labelsCount = this.previousSelection.length;
        } else {
          this.labels.data = response.labels.data;
          this.labelsCount = response.labels.count;
        }

        if (this.data) {
          if (this._readonly) {
            this.previousSelection.forEach((label) => {
              if (this.data.find((item) => item.id === label._id)) {
                this.labelListSelection.select(label);
              }
            });
          } else {
            this.labels.data.forEach((label) => {
              if (this.data.find((item) => item.id === label._id)) {
                this.labelListSelection.select(label);
              }
            });
          }
          this.updateContainedCompanies();
        }
      });
  }

  changePageLabels() {
    if (this._readonly) {
      this.paginReadOnly();
      return;
    }
    const params = {
      skip: this.paginator.pageIndex * this.paginator.pageSize,
      limit: this.paginator.pageSize,
      name: this.labelListFilter.value,
      account: this.account,
    };
    this.getLabels(params);
  }

  paginReadOnly() {
    const start = this.paginator.pageIndex * this.paginator.pageSize;
    const end = start + this.paginator.pageSize;
    if (this.filter.trim().length > 0) {
      const filter = this.previousSelection.filter((item) => item.name.toLowerCase().includes(this.filter.toLowerCase()));
      this.labels.data = filter.slice(start, end);
      this.labelsCount = filter.length;
    } else {
      this.labels.data = this.previousSelection.slice(start, end);
      this.labelsCount = this.previousSelection.length;
    }
  }

  updateContainedCompanies() {
    this.containedCompanies = _.union(...this.labelListSelection.selected.map((label) => label.companies.map((company) => company.name)));
    this.numberContainedCompanies = this.containedCompanies.length;
  }

  saveLabel() {
    const payload = {
      name: this.newLabelName.value,
      type: 'companies',
      companies: this.newGroupSelection.selected,
      account: this.account,
    };
    this.labelService.createLabel(payload).subscribe((response) => {
      this.labelListSelection.select(response.data);
      const params = {
        skip: 0,
        limit: this.paginator.pageSize,
        account: this.account,
      };
      this.newLabelName.reset();
      this.paginator.pageIndex = 0;
      this.getLabels(params);
      this.enableAddNewLabel = true;
      this.newGroupSelection.clear();
    });
  }

  addNewLabel() {
    this.newLabelName.reset();
    this.newGroupSelection.clear();
    this.enableAddNewLabel = false;
    this.newLabelFilter = '';
    this.filterNewGroupList('');
  }

  getLabelChipClass(label) {
    return this.labelListSelection.isSelected(label) ? 'blueLight-chip' : 'greyLight-chip';
  }

  filterNewGroupList(filterValue: string) {
    this.newGroupDS.filter = filterValue.trim().toLowerCase();
  }

  showLabelTemplate = false;
  public showAddLabelTemplate() {
    this.showLabelTemplate = !this.showLabelTemplate;
    if (!this._readonly && this.showLabelTemplate) {
      setTimeout(() => {
        this.newGroupDS.paginator = this.newLabelPaginator;
      });
    }
  }

  getLabelsSelected() {
    return this.labelListSelection.selected.map((item) => ({ id: item._id }));
  }
}

export class LabelListSelection {
  selected: any[] = [];
  $change = new Subject<any>();
  isSelected(label): boolean {
    return !!this.selected.filter((value) => {
      return value.name === label.name;
    }).length;
  }
  select(label): void {
    this.selected.push(label);
    const change = {
      added: label,
      removed: null,
    };
    this.$change.next(change);
  }
  deselect(label): void {
    _.remove(this.selected, label);
    const change = {
      added: null,
      removed: label,
    };
    this.$change.next(change);
  }
  toggle(label): void {
    if (this.isSelected(label)) {
      this.deselect(label);
    } else {
      this.select(label);
    }
  }
  isEmpty(): boolean {
    return !this.selected.length;
  }
  hasValue(): boolean {
    return !!this.selected.length;
  }
}
