import { Pipe, PipeTransform, Injectable } from '@angular/core';
@Pipe({
  name: 'filter',
})
@Injectable()
export class FilterTablePipe implements PipeTransform {
  transform(items: any[], field: string | string[], value: string): any[] {
    if (!items) {
      return [];
    }
    if (!field || !value) {
      return items;
    }

    return items.filter((item) => {
      const filterObj = {};
      if (Array.isArray(field)) {
        field.forEach((f) => {
          if (item[f]) filterObj[f] = item[f].trim();
        });
      } else {
        filterObj[field] = item[field].trim();
      }
      return Object.values(filterObj).join(',').toLowerCase().includes(value.toLowerCase());
    });
  }
}
