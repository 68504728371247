import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-search-button',
  templateUrl: './search-button.component.html',
  styleUrls: ['./search-button.component.scss'],
})
export class SearchButtonComponent {
  showSearch = true;
  showInput = false;

  @Output()
  public eventEmmiter = new EventEmitter();

  @Input()
  datasource;

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    if (this.datasource) {
      this.datasource.filter = filterValue.trim().toLowerCase();
    } else {
      this.eventEmmiter.emit(filterValue);
    }
  }

  changeStatus() {
    this.showSearch = !this.showSearch;
    this.showInput = !this.showInput;
    if (this.datasource) {
      this.datasource.filter = '';
    } else {
      this.eventEmmiter.emit('');
    }
  }
}
