<section class="desktop-navigation-rail-container" fxLayout="column" fxLayoutAlign="start center" fxHide fxShow.gt-sm>
  <nav>
    <a
      *ngFor="let route of routes"
      routerLink="{{ route.path }}"
      routerLinkActive="active"
      #rla="routerLinkActive"
      fxLayout="column"
      fxLayoutAlign="center center"
      class="navigation-element"
      [ngClass]="{ 'navigation-element-active': rla.isActive }"
    >
      <img [src]="route.icon" height="24" />
      <span [ngClass]="{ 'accent-text': rla.isActive }" class="body-6">{{ route.title }}</span>
    </a>
  </nav>
  <span class="flex-elm"></span>
  <div>
    <a fxLayout="column" fxLayoutAlign="center center" class="navigation-element" (click)="goToTMS()">
      <img src="assets/icons/sc-logo.svg" height="24" />
      <span class="body-6">Go to TMS</span>
    </a>
    <a fxLayout="column" fxLayoutAlign="center center" class="navigation-element" (click)="logout()">
      <mat-icon>logout</mat-icon>
      <span class="body-6">Exit</span>
    </a>
  </div>
  <div id="user" fxLayout="column" fxLayoutAlign="center center">
    <div id="userLogo" matTooltip="{{ username | titlecase }}">
      <span class="body-4">{{ username?.charAt(0) | titlecase }}</span>
    </div>
  </div>
</section>

<mat-toolbar class="mobile-bottom-navigation-container" fxHide fxShow.sm>
  <nav fxFlex fxLayout="row" fxLayoutAlign="space-around center">
    <a
      *ngFor="let route of routes"
      routerLink="{{ route.path }}"
      routerLinkActive="active"
      #rla="routerLinkActive"
      fxLayout="column"
      fxLayoutAlign="center center"
      class="navigation-element"
    >
      <img [src]="route.icon" height="24" />
      <span [ngClass]="{ 'accent-text': rla.isActive }" class="subtitle-2" fxHide fxShow.gt-sm>{{ route.title }}</span>
    </a>
    <a fxLayout="column" fxLayoutAlign="center center" class="navigation-element" (click)="goToTMS()">
      <img src="assets/icons/sc-logo.svg" height="24" />
      <span class="body-6" fxHide fxShow.gt-sm>Go to TMS</span>
    </a>
  </nav>
</mat-toolbar>

<section class="sc-main-drawer-container" fxLayout="row" *ngIf="openDrawer">
  <div class="sc-main-drawer">
    <mat-toolbar [style.background-color]="'white'">
      <button mat-icon-button fxHide.gt-xs fxShow (click)="onCloseDrawer.emit(false)">
        <mat-icon>menu</mat-icon>
      </button>
      <img [src]="companyLogo" height="32" />
    </mat-toolbar>
    <mat-list>
      <mat-list-item
        class="navigation-element"
        [ngClass]="{ 'navigation-element-active': rla.isActive }"
        *ngFor="let route of routes"
        routerLink="{{ route.path }}"
        routerLinkActive="active"
        #rla="routerLinkActive"
        (click)="onCloseDrawer.emit(false)"
      >
        <img mat-list-icon [src]="route.icon" height="24" />
        <!-- mat-icon mat-list-icon>folder</mat-icon -->
        <div [ngClass]="{ 'accent-text': rla.isActive }" mat-line>{{ route.title }}</div>
      </mat-list-item>
      <mat-list-item class="navigation-element" routerLinkActive="active" (click)="goToTMS()">
        <img mat-list-icon src="assets/icons/sc-logo.svg" height="24" />
        <div mat-line>Go to TMS</div>
      </mat-list-item>
    </mat-list>
  </div>
  <div fxFlex class="sc-main-drawer-backdrop" (click)="onCloseDrawer.emit(false)"></div>
</section>

<!-- SVG Filter -->
<svg id="svg" style="display: none">
  <defs>
    <filter id="orangeFilter">
      <feColorMatrix
        color-interpolation-filters="sRGB"
        type="matrix"
        values="0.95 0   0   0   0
                0   0.54  0   0   0
                0   0   0.09  0   0
                0   0   0   1   0 "
      />
    </filter>
  </defs>
</svg>

<svg id="svgTwo" style="display: none">
  <defs>
    <filter id="grayFilter">
      <feColorMatrix
        type="matrix"
        values="0.78     0     0     0     0
                0     0.78     0     0     0
                0     0     0.78     0     0
                0     0     0     0.5     0 "
      />
    </filter>
  </defs>
</svg>
