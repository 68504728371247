import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-role-subrole-filter-selector',
  templateUrl: './role-subrole-filter-selector.component.html',
  styleUrls: ['./role-subrole-filter-selector.component.scss'],
})
export class RoleSubroleFilterSelectorComponent {
  @Input() roles: any;
  @Input() role: FormControl;
  @Input() subRoles: any;
  @Input() subRole: FormControl;

  @Output() onSelectRole = new EventEmitter<any>();
  @Output() onClearRole = new EventEmitter<any>();
  @Output() onSelectSubrole = new EventEmitter<any>();
}
