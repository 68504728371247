<button *ngIf="showSearch" class="btn-search" mat-button round search (click)="changeStatus()">
  <img src="assets/icons/icon_search.svg" />
</button>

<div *ngIf="showInput" class="search-container">
  <input class="search-input" autocomplete="off" matInput (keyup)="applyFilter($event)" placeholder="Search" />
  <button class="search-button" mat-button round search (click)="changeStatus()">
    <span class="material-icons-outlined">arrow_forward_ios</span>
  </button>
</div>
