<!-- no mobile -->
<div fxShow.gt-xs fxHide fxLayout="row" fxLayoutGap="10px">
  <div fxFlex>
    <p class="subtitle-5 primary-text">Roles</p>
    <ng-container *ngTemplateOutlet="roleMatSelect"></ng-container>
  </div>
  <div fxFlex>
    <p class="subtitle-5 primary-text">Sub Role</p>
    <ng-container *ngTemplateOutlet="subroleMatSelect"></ng-container>
  </div>
</div>

<!-- mobile -->
<div fxShow fxHide.gt-xs fxLayout="column" fxLayoutGap="10px">
  <div>
    <p class="subtitle-5 primary-text">
      Roles
      <span class="chip-selected-role" *ngIf="role.value">
        {{ role.value }}
        <span class="material-icons-outlined" (click)="onClearRole.emit()">close</span>
      </span>
    </p>
    <div [ngStyle]="{ display: role.value ? 'none' : 'block' }">
      <ng-container *ngTemplateOutlet="roleMatSelect"></ng-container>
    </div>
  </div>
  <div *ngIf="role.value">
    <p class="subtitle-5 primary-text">Sub Role</p>
    <ng-container *ngTemplateOutlet="subroleMatSelect"></ng-container>
  </div>
</div>

<ng-template #roleMatSelect>
  <mat-form-field class="mat-form-field-no-hint">
    <mat-select placeholder="Roles" (valueChange)="onSelectRole.emit($event)" [formControl]="role">
      <mat-option *ngFor="let role of roles" [value]="role.value">
        {{ role.viewValue }}
      </mat-option>
    </mat-select>
    <mat-icon matSuffix (click)="onClearRole.emit()" *ngIf="role.value">close</mat-icon>
  </mat-form-field>
</ng-template>

<ng-template #subroleMatSelect>
  <mat-form-field class="mat-form-field-no-hint">
    <mat-select multiple placeholder="Sub Roles" [disabled]="!subRoles.length" [formControl]="subRole">
      <mat-option *ngFor="let subRole of subRoles" [value]="subRole">
        {{ subRole.viewValue }}
      </mat-option>
    </mat-select>
  </mat-form-field>
</ng-template>
