<section fxLayout="column" fxLayoutGap="15px">
  <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
    <h1 class="subtitle-4" fxFlex>{{ title }} ({{ totalList }})</h1>
    <button mat-button icon (click)="onClickAdd()">
      <img src="assets/icons/icon_add.svg" />
    </button>
  </div>

  <app-search-bar [filter]="searchFilter"></app-search-bar>
</section>
