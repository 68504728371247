<section class="sc-dialog__wrapper">
  <div class="sc-dialog__blank-space sc-dialog__blank-space--narrow" (click)="onClose.emit()"></div>
  <div class="sc-dialog__container">
    <section class="sc-dialog-title-header" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
      <h1 class="accent-text">
        <span class="primary-text">Modified Fields</span>
        Details
      </h1>
      <span fxFlex></span>
      <button mat-button b3a (click)="onClose.emit()">
        <mat-icon class="greyMedium-text">close</mat-icon>
      </button>
    </section>

    <section class="sc-dialog-content" fxLayout="column" fxLayoutGap="30px">
      <div fxLayout="row" fxLayoutGap="20px">
        <div fxLayout="column" fxLayoutGap="20px" fxFlex>
          <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="12px" fxFlex>
            <ng-container [ngSwitch]="attribute.module">
              <img class="module-icon" *ngSwitchCase="'companies'" src="assets/icons/menu_companies.svg" height="24" />
              <img class="module-icon" *ngSwitchCase="'company'" src="assets/icons/menu_companies.svg" height="24" />
              <img class="module-icon" *ngSwitchCase="'users'" src="assets/icons/menu_users.svg" height="24" />
              <img class="module-icon" *ngSwitchCase="'user'" src="assets/icons/menu_users.svg" height="24" />
              <img class="module-icon" *ngSwitchCase="'labels'" src="assets/icons/menu_labels.svg" height="24" />
              <img class="module-icon" *ngSwitchCase="'carriers'" src="assets/icons/menu_carriers.svg" height="24" />
              <img class="module-icon" *ngSwitchCase="'contracts'" src="assets/icons/menu_contracts.svg" height="24" />
              <mat-icon *ngSwitchDefault>extension</mat-icon>
            </ng-container>
            <div>
              <h4 class="subtitle-7 primary-text">Module</h4>
              <span>{{ attribute.module }}</span>
            </div>
          </div>

          <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="12px" fxFlex>
            <mat-icon *ngIf="attribute?.type?.indexOf('update') > -1">edit</mat-icon>
            <mat-icon *ngIf="attribute?.type?.indexOf('create') > -1">add_circle</mat-icon>
            <mat-icon *ngIf="attribute?.type?.indexOf('delete') > -1">delete</mat-icon>
            <div>
              <h4 class="subtitle-7 primary-text">Type</h4>
              <span>{{ attribute.type }}</span>
            </div>
          </div>
        </div>

        <div fxLayout="column" fxLayoutGap="20px" fxFlex>
          <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="12px">
            <mat-icon>storage</mat-icon>
            <div>
              <h4 class="subtitle-7 primary-text">Record Modified</h4>
              <span>{{ attribute.recordModified }}</span>
            </div>
          </div>

          <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="12px">
            <mat-icon>percent</mat-icon>
            <div>
              <h4 class="subtitle-7 primary-text">Change count</h4>
              <span>{{ paginator.length }} changes were made</span>
            </div>
          </div>
        </div>

        <div fxLayout="column" fxLayoutGap="20px" fxFlex>
          <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="12px" fxFlex>
            <mat-icon>person</mat-icon>
            <div>
              <h4 class="subtitle-7 primary-text">Modified by</h4>
              <span>{{ attribute.modifiedBy }}</span>
            </div>
          </div>

          <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="12px" fxFlex>
            <mat-icon>event</mat-icon>
            <div>
              <h4 class="subtitle-7 primary-text">Modified at</h4>

              <span>
                {{ attribute.modifiedAt | date : 'short' }} <small>({{ makeDate(attribute.modifiedAt) | exactTimeToNow }} ago)</small>
              </span>
            </div>
          </div>
        </div>
      </div>

      <div fxFlex class="sc-scrollable">
        <table id="changes-table" class="sc-table" mat-table [dataSource]="dataSource">
          <ng-container matColumnDef="field">
            <th mat-header-cell *matHeaderCellDef>Field</th>
            <td mat-cell *matCellDef="let element">
              {{ element.field }}
            </td>
          </ng-container>

          <ng-container matColumnDef="previewsValue">
            <th class="more-padding" mat-header-cell *matHeaderCellDef>Previous value</th>
            <td class="more-padding" mat-cell *matCellDef="let element">
              <app-object-visualizer [element]="element.previewsValue"></app-object-visualizer>
            </td>
          </ng-container>

          <ng-container matColumnDef="newValue">
            <th class="more-padding" mat-header-cell *matHeaderCellDef>New Value</th>
            <td class="more-padding" mat-cell *matCellDef="let element">
              <app-object-visualizer [element]="element.newValue"></app-object-visualizer>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
      </div>

      <mat-paginator
        paginator
        (page)="onPagination()"
        pageSize="10"
        [hidePageSize]="null"
        [pageSizeOptions]="[10, 20, 30, 50]"
        showFirstLastButtons
      >
      </mat-paginator>
    </section>
  </div>
</section>
