import { SelectionModel } from '@angular/cdk/collections';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { CompanyService } from '@app-main/services/company/company.service';
import * as _ from 'lodash';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-company-list',
  templateUrl: './company-list.component.html',
  styleUrls: ['./company-list.component.scss'],
})
export class CompanyListComponent implements OnInit, OnChanges {
  @Input()
  data: string[];

  @Input() wideCard = true;
  public intWideCard = true;
  public showSelected = false;

  @Input()
  readonly isCustomer: boolean;

  @Input()
  _readonly = false;
  @Input() userType: string;

  @Input() change: any;
  @Input() difference: any;
  @Input() containedCompanies: any;
  @Input() state = false;

  @Output()
  public eventEmmiter = new EventEmitter();

  companyListDS: MatTableDataSource<any>;
  companyListSelection = new SelectionModel<any>(true, []);

  associatedCompaniesDS: MatTableDataSource<any>;
  associatedCompaniesSelection = new SelectionModel<any>(true, []);

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  displayedColumns = ['company', 'select'];
  lodash = _;
  oldValueIsCustomer: boolean;

  chipFilterText = '';

  constructor(private companyService: CompanyService, private snackBar: MatSnackBar) {
    this.companyListDS = new MatTableDataSource([]);
    this.associatedCompaniesDS = new MatTableDataSource([]);
  }

  ngOnChanges(changes: any): void {
    if (this.associatedCompaniesSelection && this.isCustomer !== this.oldValueIsCustomer) {
      this.associatedCompaniesSelection = new SelectionModel<any>(!this.isCustomer ? true : false, []);
      this.oldValueIsCustomer = this.isCustomer;
    }

    if (this.change) {
      if (this.change.added) {
        this.companyListDS.data.forEach((company) => {
          if (
            _.includes(
              this.change.added.companies.map((element) => {
                return element.name;
              }),
              company.name
            )
          ) {
            this.associatedCompaniesSelection.select(company.name);
          }
        });
      }
      if (this.change.removed) {
        this.companyListDS.data.forEach((company) => {
          if (
            _.includes(
              this.change.removed.companies.map((element) => {
                return element.name;
              }),
              company.name
            ) &&
            _.includes(this.difference, company.name)
          ) {
            this.associatedCompaniesSelection.deselect(company.name);
          }
        });
      }
    }

    if (changes._readonly) {
      this.intWideCard = false;
    } else {
      this.intWideCard = changes.wideCard;
    }

    if (this.isCustomer) {
      this.associatedCompaniesSelection.clear();
    }
  }

  ngOnInit() {
    this.getCompaniesList();

    this.companyListDS.filterPredicate = (data: any, filter: string) => {
      return this.companyService.getCodeFilterValue(data).includes(filter);
    };

    this.associatedCompaniesDS.filterPredicate = (data: any, filter: string) => {
      return this.companyService.getCodeFilterValue(data).includes(filter);
    };
  }

  getCompaniesList() {
    if (this.data && this._readonly) {
      this.associatedCompaniesDS.data = this.data.map((item) => ({ name: item }));
      setTimeout(() => {
        this.associatedCompaniesDS.paginator = this.paginator;
      }, 100);
    } else {
      this.companyService
        .getCompanyInfo()
        .pipe(take(1))
        .subscribe((response) => {
          this.snackBar.dismiss();
          this.companyListDS.data = response.data;
          this.associatedCompaniesDS.data = response.data;
          this.associatedCompaniesDS.paginator = this.paginator;
          this.companyListSelection = new SelectionModel<any>(!this.isCustomer, []);

          if (this.data) {
            this.data.forEach((item) => {
              this.associatedCompaniesSelection.toggle(item);
            });
          }
        });
    }
  }

  filterCompanyList(event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.companyListDS.filter = filterValue.trim().toLowerCase();
  }

  filterAssociatedCompanies(event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.associatedCompaniesDS.filter = filterValue.trim().toLowerCase();
  }

  masterToggle() {
    this.isAllSelected()
      ? this.associatedCompaniesDS.filteredData.forEach((row) => {
          if (this.containedCompanies.length === 0 || !_.includes(this.containedCompanies, row.name)) {
            this.associatedCompaniesSelection.deselect(row.name);
          }
        })
      : this.associatedCompaniesDS.filteredData.forEach((row) => this.associatedCompaniesSelection.select(row.name));
  }

  isAllSelected() {
    const numSelected = this.associatedCompaniesSelection.selected.length;
    const numRows = this.associatedCompaniesDS.filteredData.length;
    return numSelected === numRows;
  }

  getCompaniesSelections() {
    return this.associatedCompaniesSelection.selected;
  }

  public emitChange() {
    this.eventEmmiter.emit({ items: this.associatedCompaniesSelection.selected.length, changes: true });
  }

  public onPage(): void {
    const selectedCompaniesChips = document.getElementById('selected-companies-chips');
    if (selectedCompaniesChips) {
      setTimeout(() => {
        const companyListContainer = document.getElementById('company-list-container');
        selectedCompaniesChips.style.height = companyListContainer.offsetHeight + 'px';
      }, 500);
    }
  }
}
