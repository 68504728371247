import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-loading-state',
  templateUrl: './loading-state.component.html',
  styleUrls: ['./loading-state.component.scss'],
})
export class LoadingStateComponent implements OnChanges {
  @Input() displayChange: boolean;

  @Input() customMessage: string;
  public msg = 'Loading data...';

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.customMessage?.currentValue !== null || changes?.customMessage?.currentValue !== undefined) {
      this.msg = '';

      setTimeout(() => {
        this.msg = ', hold tight';
        setTimeout(() => {
          this.msg = ', any time now';
          setTimeout(() => {
            this.msg = 'Well, this is embarrassing';
            this.customMessage = '';
          }, 10000);
        }, 5000);
      }, 5000);
    }
  }
}
