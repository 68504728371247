import { Injectable } from '@angular/core';
import { HttpService } from '@app-core/http/http.service';
import { Observable } from 'rxjs';
import { accountConfiguration } from '../../../shared/config.json';

@Injectable({
  providedIn: 'root',
})
export class AccountService {
  resource: any;

  get isArrive(): boolean {
    const config = accountConfiguration.find((element) => {
      return element.apiKey === localStorage.getItem('apiKey');
    });
    return config?.arrive || false;
  }

  constructor(private http: HttpService) {}

  // Este metodo no se esta usando
  uploadAgreement(formData): Observable<any> {
    this.resource = 'account/upload-file';
    return this.http.post<any>(this.resource, formData);
  }

  // Metodo para actualizar la cuenta
  updateAccount(id, body: any): Observable<any> {
    this.resource = `account/${id}`;
    return this.http.put<any>(this.resource, body, { observe: 'response' });
  }
}
