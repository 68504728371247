<section id="edit-users">
  <div id="blank-space" (click)="hideEdit()"></div>
  <section id="edit-section">
    <button mat-button b3a (click)="hideEdit()" *ngIf="!windows"><mat-icon>close</mat-icon></button>
    <h3 class="primary-text">
      <mat-icon color="primary" *ngIf="windows" (click)="hideEdit()">chevron_left</mat-icon>
      <span class="accent-text">{{ title }}</span> &nbsp;User
    </h3>
    <mat-horizontal-stepper color="accent">
      <mat-step>
        <ng-template matStepLabel>
          <span class="subtitle-5 accent-text">Data</span>
          <h1 class="primary-text">Main Info</h1>
        </ng-template>
        <form [formGroup]="userForm.get('mainData')">
          <ng-container formGroupName="profileInformation">
            <div>
              <p class="subtitle-5 primary-text">First Name</p>
              <mat-form-field>
                <mat-icon matPrefix>person_outline</mat-icon>
                <input formControlName="firstName" matInput placeholder="First Name" class="titlecase" maxlength="30" />
                <mat-error *ngIf="userForm.get('mainData').get('profileInformation.firstName').hasError('required')">
                  First name is required
                </mat-error>
              </mat-form-field>
            </div>

            <div>
              <p class="subtitle-5 primary-text">Last Name</p>
              <mat-form-field>
                <mat-icon matPrefix>person_outline</mat-icon>
                <input formControlName="lastName" matInput placeholder="Last Name" class="titlecase" maxlength="30" />
                <mat-error *ngIf="userForm.get('mainData').get('profileInformation.lastName').hasError('required')">
                  Last name is required
                </mat-error>
              </mat-form-field>
            </div>

            <div>
              <p class="subtitle-5 primary-text">Email</p>
              <mat-form-field>
                <mat-icon class="material-icons-outlined" matPrefix>email</mat-icon>
                <input formControlName="email" matInput placeholder="Email" autocomplete="noupe" maxlength="50" />
                <mat-error *ngIf="userForm.get('mainData').get('profileInformation.email').hasError('required')">
                  Email is required
                </mat-error>
                <mat-error *ngIf="userForm.get('mainData').get('profileInformation.email').hasError('pattern')">
                  Must be a valid email
                </mat-error>
              </mat-form-field>
            </div>
            <div>
              <p class="subtitle-5 primary-text">Occupation</p>
              <mat-form-field>
                <mat-icon class="material-icons-outlined" matPrefix>badge</mat-icon>
                <input formControlName="occupation" matInput type="text" placeholder="Occupation" class="titlecase" maxlength="30" />
                <mat-error *ngIf="userForm.get('mainData').get('profileInformation.occupation').hasError('required')">
                  Occupation is required
                </mat-error>
              </mat-form-field>
            </div>

            <div>
              <p class="subtitle-5 primary-text">Telephone</p>
              <mat-form-field>
                <mat-icon class="material-icons-outlined" matPrefix>phone</mat-icon>
                <input formControlName="phone" matInput type="tel" placeholder="Telephone" mask="(000) 000-0000" />
                <mat-error *ngIf="userForm.get('mainData').get('profileInformation.phone').hasError('required')">
                  Telephone is required
                </mat-error>
                <mat-error *ngIf="userForm.get('mainData').get('profileInformation.phone').hasError('minlength')">
                  Phone must be 10 digits
                </mat-error>
              </mat-form-field>
            </div>
          </ng-container>
          <ng-container formGroupName="password" *ngIf="title === 'Create'">
            <div>
              <p class="subtitle-5 primary-text">Password</p>
              <mat-form-field>
                <mat-icon class="material-icons-outlined" matPrefix>lock</mat-icon>
                <input formControlName="password" matInput type="password" placeholder="Password" autocomplete="new-password" />
                <mat-error *ngIf="userForm.get('mainData').get('password.password').hasError('required')"> Password is required </mat-error>
              </mat-form-field>
            </div>

            <div>
              <p class="subtitle-5 primary-text">Confirm Password</p>
              <mat-form-field>
                <mat-icon class="material-icons-outlined" matPrefix>lock</mat-icon>
                <input formControlName="confirmPassword" matInput type="password" placeholder="Password" autocomplete="noupe" />
                <mat-error *ngIf="userForm.get('mainData').get('password.confirmPassword').hasError('required')">
                  Password is required
                </mat-error>
                <mat-error *ngIf="userForm.get('mainData').get('password.confirmPassword').hasError('password')">
                  Password do not match
                </mat-error>
              </mat-form-field>
            </div>
          </ng-container>

          <mat-checkbox *ngIf="userForm.get('authorization.permission').value !== 'customer'" (change)="registerCompanies($event.checked)"
            >Add Associated Companies</mat-checkbox
          >

          <div id="reset-password">
            <button class="reset-password" mat-button b2 small (click)="resetPassword()" *ngIf="title === 'Edit'">RESET PASSWORD</button>
          </div>
        </form>
      </mat-step>

      <mat-step>
        <ng-template matStepLabel>
          <span class="subtitle-5 accent-text">Data</span>
          <h1 class="primary-text">Auth</h1>
        </ng-template>
        <h4 class="primary-text">AUTHORIZATION</h4>
        <form [formGroup]="userForm.get('authorization')">
          <div>
            <p class="subtitle-5 primary-text">User Type</p>
            <mat-form-field>
              <mat-select formControlName="role" placeholder="User Type" disableOptionCentering>
                <mat-option value="customer">Customer</mat-option>
                <mat-option value="AccountUser">Account User</mat-option>
                <mat-option value="AccountAdministrator">Account Administrator</mat-option>
                <mat-option value="userTracker">User Tracker</mat-option>
              </mat-select>
              <mat-error *ngIf="userForm.get('authorization.role').hasError('required')"> User Type is required </mat-error>
            </mat-form-field>
          </div>
          <div>
            <p class="subtitle-5 primary-text">Role</p>
            <mat-form-field>
              <mat-icon class="material-icons-outlined" matPrefix>badge</mat-icon>
              <mat-select formControlName="permission" placeholder="Role" disableOptionCentering>
                <mat-option value="customer">Customer</mat-option>
                <mat-option value="AccountUser">Account User</mat-option>
                <mat-option value="AccountAdministrator">Account Administrator</mat-option>
                <mat-option value="userTracker">User Tracker</mat-option>
              </mat-select>
              <mat-error> Role is required </mat-error>
            </mat-form-field>
          </div>
        </form>
        <mat-divider></mat-divider>
        <h4 class="primary-text">NOTIFICATIONS</h4>
        <form [formGroup]="userForm.get('notifications')">
          <mat-slide-toggle formControlName="desktop">Desktop</mat-slide-toggle>
          <mat-slide-toggle formControlName="email">Email</mat-slide-toggle>
        </form>
      </mat-step>
    </mat-horizontal-stepper>

    <button mat-button b1 (click)="create()" *ngIf="title === 'Create'">CREATE</button>
    <button mat-button b1 (click)="save()" *ngIf="title === 'Edit'">SAVE</button>
  </section>
</section>
