import { Pipe, PipeTransform } from '@angular/core';
import { formatDistanceToNowStrict } from 'date-fns';

@Pipe({
  name: 'exactTimeToNow',
})
export class ExactTimeToNowPipe implements PipeTransform {
  transform(value: Date): any {
    if (value) {
      return formatDistanceToNowStrict(value, { addSuffix: false, roundingMethod: 'floor' });
    } else {
      return value;
    }
  }
}
