import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AlertDialogComponent } from '@shared/components/alert-dialog/alert-dialog.component';
import { GenericDialogComponent } from '@shared/components/generic-dialog/generic-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class AlertService {
  constructor(private dialog: MatDialog, private snackBar: MatSnackBar) {}

  confirm(content: string) {
    const dialog = this.dialog.open(GenericDialogComponent, {
      data: {
        title: 'Confirm',
        content,
      },
      panelClass: 'alert-dialog',
      backdropClass: 'alert-dialog-backdrop',
    });

    return dialog.afterClosed();
  }

  alert(content: string) {
    const dialog = this.dialog.open(AlertDialogComponent, {
      data: {
        title: 'Alert!',
        content,
      },
      panelClass: 'alert-dialog',
      backdropClass: 'alert-dialog-backdrop',
    });

    setTimeout(() => {
      dialog.close();
    }, 5000);
  }

  customDialog(title: string, content: string) {
    const dialog = this.dialog.open(GenericDialogComponent, {
      data: {
        title,
        content,
      },
      panelClass: 'alert-dialog',
      backdropClass: 'alert-dialog-backdrop',
    });

    return dialog.afterClosed();
  }

  success(message) {
    this.snackBar.open(message, 'Close', {
      duration: 3000,
      horizontalPosition: 'right',
      verticalPosition: 'top',
      panelClass: 'success',
    });
  }

  error(message) {
    this.snackBar.open(message, 'Close', {
      duration: 3000,
      horizontalPosition: 'right',
      verticalPosition: 'top',
      panelClass: 'error',
    });
  }

  customSnackBar(message, duration, horizontalPosition, verticalPosition, panelClass) {
    this.snackBar.open(message, 'Close', {
      duration,
      horizontalPosition,
      verticalPosition,
      panelClass,
    });
  }

  addSlideOut(tag: string): void {
    const domElm = document.getElementsByTagName(tag);
    domElm[0].className = 'sc-side-dialog slideOut';
  }
}
