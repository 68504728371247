import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { HistoryService } from '@app-main/services/history/history.service';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { displayNameMap } from '@shared/models/Breakpoint.model';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-audit-history',
  templateUrl: './audit-history.component.html',
  styleUrls: ['./audit-history.component.scss'],
})
export class AuditHistoryComponent implements OnInit, OnDestroy {
  ngUnsubscribe = new Subject<void>();
  public currentScreenSize: string;

  public filter: FormControl = new FormControl('');
  public displayedColumns: string[] = ['module', 'type', 'recordModified', 'modifiedBy', 'field', 'previousVal', 'newVal', 'actions']; // 'modifiedAt',
  public dataSource: MatTableDataSource<any>;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  public selectedChange: any;

  constructor(private _historyService: HistoryService, private _breakpointObserver: BreakpointObserver) {
    this.dataSource = new MatTableDataSource<any>([]);

    this._breakpointObserver
      .observe([Breakpoints.XSmall, Breakpoints.Small, Breakpoints.Medium, Breakpoints.Large, Breakpoints.XLarge])
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((result) => {
        for (const query of Object.keys(result.breakpoints)) {
          if (result.breakpoints[query]) {
            this.currentScreenSize = displayNameMap.get(query);

            if (this.currentScreenSize === 'Large' || this.currentScreenSize === 'XLarge') {
              this.displayedColumns = ['module', 'type', 'recordModified', 'modifiedBy', 'field', 'previousVal', 'newVal', 'actions'];
            } else if (this.currentScreenSize === 'Medium' || this.currentScreenSize === 'Large' || this.currentScreenSize === 'XLarge') {
              this.displayedColumns = ['type', 'recordModified', 'modifiedBy', 'field', 'previousVal', 'newVal', 'actions'];
            } else if (this.currentScreenSize === 'Small' || this.currentScreenSize === 'Large' || this.currentScreenSize === 'XLarge') {
              this.displayedColumns = ['type', 'recordModified', 'modifiedBy', 'field', 'previousVal', 'newVal', 'actions'];
            } else if (this.currentScreenSize === 'XSmall') {
              this.displayedColumns = ['type', 'recordModified', 'modifiedBy', 'field', 'previousVal', 'newVal', 'actions'];
            }
          }
        }
      });
  }

  ngOnInit(): void {
    this.getHistoryItems(false);
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  // TODO: method to execute an http call to get the history items
  public getHistoryItems(resetPag: boolean): void {
    const resource = `histories?page=${this.paginator.pageIndex + 1}&pageSize=${this.paginator.pageSize}&search=${this.filter.value || ''}`;

    this._historyService.getHistoryItems(resource).subscribe((response) => {
      this.paginator.length = response.total;
      this.dataSource.data = response.data;
      if (resetPag) {
        this.paginator.firstPage();
      }

      this.dataSource.data.forEach((change) => {
        // gotta review if that's an object
        try {
          const obj = JSON.parse(change.newValue);

          if (typeof obj === 'object') {
            change.newValue = obj;
          }
        } catch (error) {
          // welp
        }
      });
    });
  }

  public makeDate(date): Date {
    return new Date(date);
  }

  public localTypeOf(val): string {
    return typeof val;
  }
}
