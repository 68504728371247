<div id="newGroup">
  <div>
    <div id="groupName" [ngClass]="{ 'd-none': isMobile() }">
      <div>
        <div id="title">
          <h2 class="primary-text">Add new Company Group</h2>
          <p class="body-2">Give a name to the Label</p>
        </div>
        <mat-form-field small>
          <input matInput placeholder="Label" [formControl]="newLabelName" />
          <mat-error>Required</mat-error>
        </mat-form-field>
        <div id="title">
          <h2 class="primary-text">Companies Contained</h2>
          <span class="numberSelected" *ngIf="!newGroupSelection.isEmpty()"> +{{ newGroupSelection.selected.length }} </span>
        </div>
        <div id="containedCompanies">
          <mat-chip-list>
            <mat-chip *ngFor="let company of newGroupSelection.selected" class="geryVeryLight-chip">
              <span class="subtitle-3 primary-text">{{ company.name }}</span>
              <mat-icon (click)="newGroupSelection.toggle(company)" class="primary-text" matChipRemove> close </mat-icon>
            </mat-chip>
          </mat-chip-list>
        </div>
      </div>
    </div>
    <div id="table">
      <div id="title" fxHide.gt-sm fxShow>
        <h2 class="primary-text">Add new Company Group</h2>
        <p class="body-2">Give a name to the Label</p>
      </div>
      <mat-form-field
        small
        *ngIf="(isMobile() && !showNameLabel) || (isMobile() && !newLabelName.value && showNameLabel)"
        [ngClass]="{ search: isMobile() }"
      >
        <input matInput placeholder="Label" [formControl]="newLabelName" (blur)="showNameLabel = true" />
        <mat-error>Required</mat-error>
      </mat-form-field>

      <div class="row mb-3" *ngIf="showNameLabel && newLabelName.value">
        <div class="col">
          <mat-chip class="blueLight-chip chip-companies">
            <span class="subtitle-3 white-text">
              {{ newLabelName.value }}
            </span>
            <mat-icon (click)="showNameLabel = false" class="white-text" matChipRemove> close </mat-icon>
          </mat-chip>
        </div>
      </div>

      <div id="title">
        <h2 class="primary-text">Select Companies</h2>
        <p class="body-2 primary-text">Select the companies contained by the Label</p>
      </div>

      <mat-form-field small [ngClass]="{ search: isMobile() }">
        <mat-icon matPrefix>search</mat-icon>
        <input matInput placeholder="Search" (keyup)="filterNewGroupList($event.target.value)" [(ngModel)]="newLabelFilter" />
      </mat-form-field>
      <div [ngClass]="{ 'table-responsive max-h-190': isMobile() }">
        <table mat-table [dataSource]="newGroupDS" [ngClass]="{ 'table table-mobile': isMobile() }">
          <ng-container matColumnDef="company">
            <td mat-cell *matCellDef="let element">
              <div class="company">
                <span class="company-logo">
                  <img [src]="element.logo" onerror="this.src = 'assets/icons/icon_company.svg'" />
                </span>
                <p class="subtitle-7 primary-text">{{ element.name }}</p>
              </div>
            </td>
          </ng-container>
          <ng-container matColumnDef="select">
            <td mat-cell *matCellDef="let element">
              <mat-checkbox
                light
                (click)="$event.stopPropagation()"
                (change)="$event ? newGroupSelection.toggle(element) : null"
                [checked]="newGroupSelection.isSelected(element)"
              >
              </mat-checkbox>
            </td>
          </ng-container>
          <tr
            [ngClass]="{ disabled: !newGroupSelection.isSelected(row) }"
            (click)="newGroupSelection.toggle(row)"
            mat-row
            *matRowDef="let row; columns: ['company', 'select']"
          ></tr>
        </table>
      </div>
      <mat-paginator
        paginator
        [pageSize]="5"
        [pageSizeOptions]="[5, 10, 25]"
        showFirstLastButtons
        [hidePageSize]="currentScreenSize === 'XSmall'"
      ></mat-paginator>
    </div>
  </div>
  <div id="actions">
    <button mat-button xsmall (click)="saveLabel()" [disabled]="!newLabelName.valid || newGroupSelection.isEmpty()">Save</button>
  </div>
</div>
