import { Pipe, PipeTransform, Injectable } from '@angular/core';
@Pipe({
  name: 'filterVctStr',
})
@Injectable()
export class FilterVctStrPipe implements PipeTransform {
  transform(items: any[], value: string): any[] {
    if (!items) {
      return [];
    }
    if (!value) {
      return items;
    }

    return items.filter((item) => item.toLowerCase().indexOf(value.toLowerCase()) > -1);
  }
}
