import { Component } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-showcase',
  templateUrl: './showcase.component.html',
  styleUrls: ['./showcase.component.scss'],
})
export class ShowcaseComponent {
  email = new FormControl('', [Validators.required, Validators.email]);
  selected: any;

  altSelect = new FormControl('');
  altSelect2 = new FormControl('');
  altSelect3 = new FormControl('');

  office = new FormControl('', [Validators.required]);
  userType = new FormControl('', [Validators.required]);
  role = new FormControl('', [Validators.required]);
  boss = new FormControl('', [Validators.required]);

  foods: any[] = [
    { value: 'steak-0', viewValue: 'Steak' },
    { value: 'pizza-1', viewValue: 'Pizza' },
    { value: 'tacos-2', viewValue: 'Tacos' },
  ];

  offices: any[] = [
    { value: 'steak-0', viewValue: 'ATX' },
    { value: 'pizza-1', viewValue: 'CHI' },
    { value: 'tacos-2', viewValue: 'CHA' },
  ];

  userTypes: any[] = [
    { value: 'steak-0', viewValue: 'Customer' },
    { value: 'pizza-1', viewValue: 'Account User' },
    { value: 'tacos-2', viewValue: 'Account Administrator' },
  ];

  roles: any[] = [
    { value: 'steak-0', viewValue: 'Manager' },
    { value: 'pizza-1', viewValue: 'Sales Representative' },
    { value: 'tacos-2', viewValue: 'S.R Assistant' },
  ];

  bosses: any[] = [
    { value: 'steak-0', viewValue: 'Begy' },
    { value: 'pizza-1', viewValue: 'Diaz' },
    { value: 'tacos-2', viewValue: 'ARVY' },
    { value: 'tacos-2', viewValue: 'Account Administrator' },
  ];

  change = false;

  constructor() {
    setTimeout(() => {
      this.change = true;
    }, 3000);
  }
  getErrorMessage() {
    if (this.email.hasError('required')) {
      return 'You must enter a value';
    }

    return this.email.hasError('email') ? 'Not a valid email' : '';
  }
}
