import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-primitive-element',
  templateUrl: './primitive-element.component.html',
  styleUrls: ['./primitive-element.component.scss'],
})
export class PrimitiveElementComponent {
  @Input() primitive: any;
}
