import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'myFilter',
})
export class MyFilterPipe implements PipeTransform {
  transform(items: any[], keyword: string, properties: string[]): any[] {
    if (!items) return [];
    if (!keyword) return items;
    return items.filter((item) => {
      let itemFound: boolean;
      for (let i = 0; i < properties.length; i++) {
        if (item['value'][properties[i]].toLowerCase().indexOf(keyword.toLowerCase()) !== -1) {
          itemFound = true;
          break;
        }
      }
      return itemFound;
    });
  }
}
