<mat-card class="mat-elevation-z2">
  <mat-card-header>
    <mat-card-title>
      <h2 class="subtitle-5">Companies by groups ({{ labels.data?.length }})</h2>
    </mat-card-title>
  </mat-card-header>

  <mat-card-content>
    <mat-chip-list fxLayoutAlign="start start">
      <mat-chip
        *ngFor="let label of labels.data"
        [class]="getLabelChipClass(label)"
        (click)="_readonly ? null : labelListSelection.toggle(label)"
      >
        <span class="subtitle-3 white-text">{{ label.name | titlecase }}</span>
        <mat-icon
          (click)="labelListSelection.deselect(label)"
          *ngIf="labelListSelection.isSelected(label) && !_readonly"
          class="white-text"
          matChipRemove
        >
          close
        </mat-icon>
        <mat-icon
          (click)="labelListSelection.select(label)"
          *ngIf="!labelListSelection.isSelected(label) && !_readonly"
          class="white-text"
          matChipRemove
        >
          add
        </mat-icon>
      </mat-chip>
    </mat-chip-list>
  </mat-card-content>

  <div class="horizontal-space"></div>

  <mat-card-header>
    <mat-card-title>
      <h2 class="subtitle-5">Contained Companies ({{ containedCompanies.length }})</h2>
    </mat-card-title>
  </mat-card-header>

  <mat-card-content>
    <mat-form-field small>
      <mat-icon matPrefix>filter_list</mat-icon>
      <input matInput placeholder="Filter" [(ngModel)]="chipFilterText" />
    </mat-form-field>

    <div id="containedCompanies" class="companies-chips__container sc-scrollable">
      <mat-chip-list>
        <mat-chip *ngFor="let company of containedCompanies | filterVctStr : chipFilterText" class="geryVeryLight-chip">
          <span class="subtitle-3 primary-text">{{ company }}</span>
        </mat-chip>
      </mat-chip-list>
    </div>
  </mat-card-content>
</mat-card>

<!-- section id="user-companies">
  <div id="dialogs" *ngIf="!state">
    <ng-container *ngTemplateOutlet="!showLabelTemplate ? companyGroups : newGroup"></ng-container>
  </div>
  <div id="container-mobile" *ngIf="state">
    <ng-container *ngTemplateOutlet="groupMobile"></ng-container>
  </div>
</section>

<ng-template #companyGroups>
  <div id="groups">
    <div id="groupList">
      <div id="title">
        <h2 class="primary-text">Select Company Groups</h2>
        <span class="numberSelected" *ngIf="labelListSelection.hasValue()"> +{{ labelListSelection.selected.length }} </span>
      </div>
      <div id="table">
        <mat-form-field small>
          <mat-icon matPrefix>search</mat-icon>
          <input matInput placeholder="Search" [formControl]="labelListFilter" />
        </mat-form-field>
        <mat-chip-list table>
          <mat-chip
            *ngFor="let label of labels.data"
            [class]="getLabelChipClass(label)"
            (click)="_readonly ? null : labelListSelection.toggle(label)"
          >
            <span class="subtitle-3 white-text">{{ label.name | titlecase }}</span>
            <mat-icon
              (click)="labelListSelection.deselect(label)"
              *ngIf="labelListSelection.isSelected(label) && !_readonly"
              class="white-text"
              matChipRemove
            >
              close
            </mat-icon>
            <mat-icon
              (click)="labelListSelection.select(label)"
              *ngIf="!labelListSelection.isSelected(label) && !_readonly"
              class="white-text"
              matChipRemove
            >
              add
            </mat-icon>
          </mat-chip>
        </mat-chip-list>
        <mat-paginator
          (page)="changePageLabels()"
          paginator
          [length]="labelsCount"
          [pageSize]="8"
          [pageSizeOptions]="[8, 16, 32]"
          showFirstLastButtons
        >
        </mat-paginator>
      </div>
    </div>
    <div id="containedCompanies">
      <div>
        <h2 class="primary-text">Companies Contained</h2>
        <div>
          <mat-chip-list>
            <mat-chip *ngFor="let company of containedCompanies" class="geryVeryLight-chip">
              <span class="subtitle-3 primary-text">{{ company }}</span>
            </mat-chip>
          </mat-chip-list>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #groupMobile>
  <div id="groups-mobile">
    <div id="groupList-mobile">
      <div id="table">
        <mat-form-field small>
          <mat-icon matPrefix>search</mat-icon>
          <input matInput placeholder="Search" [formControl]="labelListFilter" />
        </mat-form-field>
        <mat-chip-list table>
          <mat-chip
            *ngFor="let label of labels.data"
            [class]="getLabelChipClass(label)"
            (click)="_readonly ? null : labelListSelection.toggle(label)"
          >
            <span class="subtitle-3 white-text">{{ label.name | titlecase }}</span>
            <mat-icon
              (click)="labelListSelection.deselect(label)"
              *ngIf="labelListSelection.isSelected(label) && !_readonly"
              class="white-text"
              matChipRemove
            >
              close
            </mat-icon>
            <mat-icon
              (click)="labelListSelection.select(label)"
              *ngIf="!labelListSelection.isSelected(label) && !_readonly"
              class="white-text"
              matChipRemove
            >
              add
            </mat-icon>
          </mat-chip>
        </mat-chip-list>
        <mat-paginator
          (page)="changePageLabels()"
          paginator
          [length]="labelsCount"
          [pageSize]="8"
          [pageSizeOptions]="[8, 16, 32]"
          showFirstLastButtons
        >
        </mat-paginator>
      </div>
    </div>
    <div id="containedCompanies-mobile">
      <div>
        <h2 class="primary-text">Companies Contained</h2>
        <div>
          <mat-chip-list>
            <mat-chip *ngFor="let company of containedCompanies" class="geryVeryLight-chip">
              <span class="subtitle-3 primary-text">{{ company }}</span>
            </mat-chip>
          </mat-chip-list>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #newGroup>
  <div id="newGroup" *ngIf="showLabelTemplate">
    <div>
      <div id="groupName">
        <div>
          <div id="title">
            <h2 class="primary-text">Add new Company Group</h2>
            <p class="body-2">Give a name to the Label</p>
          </div>
          <mat-form-field small>
            <input matInput placeholder="Label" [formControl]="newLabelName" />
            <mat-error>Required</mat-error>
          </mat-form-field>
          <div id="title">
            <h2 class="primary-text">Companies Contained</h2>
            <span class="numberSelected" *ngIf="!newGroupSelection.isEmpty()"> +{{ newGroupSelection.selected.length }} </span>
          </div>
          <div id="containedCompanies">
            <mat-chip-list>
              <mat-chip *ngFor="let company of newGroupSelection.selected" class="geryVeryLight-chip">
                <span class="subtitle-3 primary-text">{{ company.name }}</span>
                <mat-icon (click)="newGroupSelection.toggle(company)" class="primary-text" matChipRemove> close </mat-icon>
              </mat-chip>
            </mat-chip-list>
          </div>
        </div>
      </div>
      <div id="table">
        <div id="title">
          <h2 class="primary-text">Select Companies</h2>
          <p class="body-2 primary-text">Select the companies contained by the Label</p>
        </div>
        <mat-form-field small>
          <mat-icon matPrefix>search</mat-icon>
          <input matInput placeholder="Search" (keyup)="filterNewGroupList($event.target.value)" [(ngModel)]="newLabelFilter" />
        </mat-form-field>
        <table mat-table [dataSource]="newGroupDS">
          <ng-container matColumnDef="company">
            <td mat-cell *matCellDef="let element">
              <div class="company">
                <span class="company-logo">
                  <img [src]="element.logo" onerror="this.src = 'assets/icons/icon_company.svg'" />
                </span>
                <p class="subtitle-7 primary-text">{{ element.name }}</p>
              </div>
            </td>
          </ng-container>
          <ng-container matColumnDef="select">
            <td mat-cell *matCellDef="let element">
              <mat-checkbox
                light
                (click)="$event.stopPropagation()"
                (change)="$event ? newGroupSelection.toggle(element) : null"
                [checked]="newGroupSelection.isSelected(element)"
              >
              </mat-checkbox>
            </td>
          </ng-container>
          <tr (click)="newGroupSelection.toggle(row)" mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
        <mat-paginator
          #newLabelPaginator
          paginator
          [pageSize]="5"
          [pageSizeOptions]="[5, 10, 25]"
          showFirstLastButtons
          [hidePageSize]="currentScreenSize === 'XSmall'"
        ></mat-paginator>
      </div>
    </div>
    <div id="actions">
      <button mat-button xsmall (click)="saveLabel()" [disabled]="!newLabelName.valid || newGroupSelection.isEmpty()">Save</button>
    </div>
  </div>
</ng-template -->
