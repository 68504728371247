import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-info-cell',
  templateUrl: './info-cell.component.html',
  styleUrls: ['./info-cell.component.scss'],
})
export class InfoCellComponent {
  @Input() title: string;
  @Input() content: string;
  @Input() isContentBadge: boolean;
  @Input() badgeType: string;
}
