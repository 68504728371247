import { Pipe, PipeTransform, Injectable } from '@angular/core';
@Pipe({
  name: 'upliftRound',
})
@Injectable()
export class UpliftRoundPipe implements PipeTransform {
  transform(value: any) {
    if (value) {
      const rightLeft = value.split('.');

      if (rightLeft[1]?.length > 2) {
        return parseFloat(value).toFixed(2) + '%';
      } else {
        return value;
      }
    } else {
      return value;
    }
  }
}
