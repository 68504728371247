<section id="showcase">
  <div id="button" (click)="menuTrigger.openMenu()">
    <mat-icon #menuTrigger="matMenuTrigger" [matMenuTriggerFor]="menu">more_vert</mat-icon>
  </div>
  <mat-menu #menu="matMenu">
    <button mat-menu-item>
      <mat-icon>dialpad</mat-icon>
      <span>Redial</span>
    </button>
    <button mat-menu-item disabled>
      <mat-icon>voicemail</mat-icon>
      <span>Check voice mail</span>
    </button>
    <button mat-menu-item>
      <mat-icon>notifications_off</mat-icon>
      <span>Disable alerts</span>
    </button>
  </mat-menu>
  <h1>Showcase</h1>

  <br />
  <hr />
  <br />

  <!-- Fonts and Typography -->
  <h6>Fonts & Typography</h6>
  <br />
  <h1>Heading 1</h1>
  <h2>Heading 2</h2>
  <h3>Heading 3</h3>
  <h4>Heading 4</h4>
  <h5>Heading 5</h5>
  <h6>Heading 6</h6>

  <br />
  <hr />
  <br />

  <!-- Buttons -->
  <h6>Buttons</h6>
  <br />
  <div id="buttons">
    <button mat-button>b1</button>
    <button mat-button small>b1 small</button>
    <button mat-button b2>b2</button>
    <button mat-button b3><img src="assets/icons/icon_user_white.svg" /></button>
    <button mat-button b3a>b3a</button>
  </div>

  <br />
  <hr />
  <br />

  <!-- Field Options -->
  <h6>Field Options</h6>
  <br />
  <div style="padding: 10px; display: flex; flex-direction: column">
    <mat-form-field>
      <mat-icon matPrefix class="material-icons-outlined">place</mat-icon>
      <input matInput placeholder="Jon Doe" />
    </mat-form-field>

    <mat-form-field>
      <mat-icon matPrefix class="material-icons-outlined">email</mat-icon>
      <input matInput placeholder="pat@example.com" [formControl]="email" required />
      <mat-error *ngIf="email.invalid">{{ getErrorMessage() }}</mat-error>
    </mat-form-field>

    <mat-form-field>
      <img src="assets/icons/icon_password.svg" matPrefix />
      <input matInput type="password" matInput />
    </mat-form-field>

    <mat-form-field>
      <img src="assets/icons/icon_phone.svg" matPrefix />
      <input matInput type="tel" placeholder="(555) 555 5555" />
    </mat-form-field>

    <mat-form-field>
      <img src="assets/icons/icon_department.svg" matPrefix />
      <input matInput type="text" />
    </mat-form-field>

    <mat-form-field>
      <mat-select disableOptionCentering placholder="Food">
        <mat-option *ngFor="let food of foods" [value]="food.value">
          {{ food.viewValue }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <br />
    <mat-form-field>
      <mat-select disableOptionCentering placeholder="Hello" #select>
        <mat-select-trigger>{{ select.value }} {{ foods.length }}</mat-select-trigger>
        <mat-option *ngFor="let food of foods" [value]="food.viewValue">
          {{ food.viewValue }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <br />
    <div class="stepped-select">
      <mat-form-field alt floatLabel="always">
        <div class="flex-container">
          <mat-icon *ngIf="office.valid" class="step-valid">check_circle</mat-icon>
          <span *ngIf="!office.valid" matBadge="1" matBadgeSize="large" style="margin-top: 12px; margin-right: 2%"></span>
          <div class="ml-3 flex-column">
            <mat-label>Office</mat-label>
            <mat-select disableOptionCentering placeholder="" [formControl]="office">
              <mat-option *ngFor="let office of offices" [value]="office.viewValue">
                {{ office.viewValue }}
              </mat-option>
            </mat-select>
          </div>
        </div>
      </mat-form-field>

      <div class="separator"></div>
      <mat-form-field alt floatLabel="always">
        <div class="flex-container">
          <mat-icon *ngIf="userType.valid" class="step-valid">check_circle</mat-icon>
          <span *ngIf="!userType.valid" matBadge="2" matBadgeSize="large" style="margin-top: 12px; margin-right: 2%"></span>
          <div class="ml-3 flex-column">
            <mat-label>User type</mat-label>
            <mat-select disableOptionCentering placeholder="" [formControl]="userType">
              <mat-option *ngFor="let userType of userTypes" [value]="userType.viewValue">
                {{ userType.viewValue }}
              </mat-option>
            </mat-select>
          </div>
        </div>
      </mat-form-field>

      <div class="separator"></div>
      <mat-form-field alt floatLabel="always">
        <div class="flex-container">
          <mat-icon *ngIf="role.valid" class="step-valid">check_circle</mat-icon>
          <span *ngIf="!role.valid" matBadge="3" matBadgeSize="large" style="margin-top: 12px; margin-right: 2%"></span>
          <div class="ml-3 flex-column">
            <mat-label>Roles</mat-label>
            <mat-select disableOptionCentering placeholder="" [formControl]="role">
              <mat-option *ngFor="let role of roles" [value]="role.viewValue">
                {{ role.viewValue }}
              </mat-option>
            </mat-select>
          </div>
        </div>
      </mat-form-field>

      <div class="separator"></div>
      <mat-form-field alt floatLabel="always">
        <div class="flex-container">
          <mat-icon *ngIf="boss.valid" class="step-valid">check_circle</mat-icon>
          <span *ngIf="!boss.valid" matBadge="4" matBadgeSize="large" style="margin-top: 12px; margin-right: 2%"></span>
          <div class="ml-3 flex-column">
            <mat-label>Inmediate Boss</mat-label>
            <mat-select disableOptionCentering placeholder="" [formControl]="boss" multiple>
              <mat-option *ngFor="let boss of bosses" [value]="boss.viewValue">
                {{ boss.viewValue }}
              </mat-option>
            </mat-select>
          </div>
        </div>
      </mat-form-field>
    </div>
  </div>

  <br />
  <mat-paginator paginator [length]="100" [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 100]" showFirstLastButtons> </mat-paginator>

  <br /><br /><br /><br /><br /><br /><br /><br />
</section>
