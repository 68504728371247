<div class="sc-main-page-container" fxLayout="row">
  <div fxFlex fxLayout="column" class="sc-main-page-content" fxLayoutGap="15px">
    <mat-card fxLayout="column" fxFill>
      <mat-card-header fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="12px">
        <mat-card-title>
          <h1 class="subtitle-4" fxFlex>History</h1>
        </mat-card-title>
        <span fxFlex></span>
        <span class="body-5 greyMedium-text">
          {{ paginator.length }} result<span *ngIf="paginator.length > 1 || paginator.length === 0">s</span>
        </span>
        <div fxLayout="row">
          <input search type="text" placeholder="search..." [formControl]="filter" (keyup.enter)="getHistoryItems(true)" />
        </div>
        <button mat-button b3 (click)="getHistoryItems(false)">
          <mat-icon>refresh</mat-icon>
        </button>
      </mat-card-header>

      <div fxLayout="column" fxLayout.gt-sm="row" fxLayoutAlign.gt-sm="start center" fxLayoutGap="15px" style="padding-bottom: 10px">
        <span fxFlex fxHide fxShow.gt-md></span>
      </div>

      <mat-card-content fxFlex class="sc-scrollable" *ngIf="dataSource.data.length > 0">
        <table class="sc-table" mat-table [dataSource]="dataSource">
          <ng-container matColumnDef="module">
            <th mat-header-cell *matHeaderCellDef>Module</th>
            <td mat-cell *matCellDef="let element">
              <div fxLayout="row" fxLayoutAlign="start center">
                <span class="body-2" [ngSwitch]="element.module">
                  <img class="module-icon" *ngSwitchCase="'companies'" src="assets/icons/menu_companies.svg" height="18" />
                  <img class="module-icon" *ngSwitchCase="'users'" src="assets/icons/menu_users.svg" height="18" />
                  <img class="module-icon" *ngSwitchCase="'labels'" src="assets/icons/menu_labels.svg" height="18" />
                  <img class="module-icon" *ngSwitchCase="'carriers'" src="assets/icons/menu_carriers.svg" height="18" />
                  <img class="module-icon" *ngSwitchCase="'contracts'" src="assets/icons/menu_contracts.svg" height="18" />
                  <img class="module-icon" *ngSwitchCase="'settings'" src="assets/icons/menu_settings.svg" height="18" />
                  <mat-icon *ngSwitchDefault>extension</mat-icon>
                </span>
                &nbsp;
                <span fxHide fxShow.gt-md class="body-2">{{ element.module }}</span>
              </div>
            </td>
          </ng-container>

          <ng-container matColumnDef="type">
            <th mat-header-cell *matHeaderCellDef>Type</th>
            <td mat-cell *matCellDef="let element">
              <div fxLayout="row" fxLayoutAlign="start center">
                <mat-icon class="type-icon" *ngIf="element?.type?.indexOf('update') > -1">edit</mat-icon>
                <mat-icon class="type-icon" *ngIf="element?.type?.indexOf('create') > -1">add_circle</mat-icon>
                <mat-icon class="type-icon" *ngIf="element?.type?.indexOf('delete') > -1">delete</mat-icon>
                &nbsp;
                <span class="body-2">{{ element.type }}</span>
              </div>
            </td>
          </ng-container>

          <ng-container matColumnDef="recordModified">
            <th mat-header-cell *matHeaderCellDef>Record Modified</th>
            <td mat-cell *matCellDef="let element">
              <span class="body-2">{{ element.recordModified }}</span>
            </td>
          </ng-container>

          <ng-container matColumnDef="modifiedBy">
            <th mat-header-cell *matHeaderCellDef>Modified by</th>
            <td mat-cell *matCellDef="let element">
              <span class="body-2">{{ element.modifiedBy }}</span>
              <br />
              <span class="body-2 modified-time" matTooltip="{{ element.modifiedAt | date : 'short' }}"
                >{{ makeDate(element.modifiedAt) | exactTimeToNow }} ago</span
              >
            </td>
          </ng-container>

          <ng-container matColumnDef="modifiedAt">
            <th mat-header-cell *matHeaderCellDef>Modified At</th>
            <td mat-cell *matCellDef="let element">
              <span class="body-2">{{ element.modifiedAt | date : 'short' }}</span>
              <br />
              <span class="body-2">{{ makeDate(element.modifiedAt) | exactTimeToNow }} ago</span>
            </td>
          </ng-container>

          <ng-container matColumnDef="field">
            <th mat-header-cell *matHeaderCellDef>Field</th>
            <td mat-cell *matCellDef="let element">
              <b class="accent-text" *ngIf="element.changesType === 'multiple'">Multiple</b>
              <span class="body-2" *ngIf="element.changesType === 'single'">{{ element.field }}</span>
            </td>
          </ng-container>

          <ng-container matColumnDef="previousVal">
            <th mat-header-cell *matHeaderCellDef>Previous value</th>
            <td mat-cell *matCellDef="let element">
              <!-- span class="body-2" *ngIf="element.changesType === 'multiple'">...</span>
              <span class="body-2" *ngIf="element.changesType === 'single'" -->
              <app-object-visualizer [element]="element.previewsValue"></app-object-visualizer>
              <!-- /span -->
            </td>
          </ng-container>

          <ng-container matColumnDef="newVal">
            <th mat-header-cell *matHeaderCellDef>New Value</th>
            <td mat-cell *matCellDef="let element">
              <span class="body-2" *ngIf="element.changesType === 'multiple'">
                <b>+{{ element.changes.length > 99 ? '99' : element.changes.length - 1 }}</b> changes
              </span>
              <!-- span class="body-2" *ngIf="element.changesType === 'multiple'"
                matBadge="{{element.changes.length > 99 ? '+99' : element.changes.length }}"
                matBadgeOverlap="false">Changes</span -->
              <!-- span *ngIf="element.changesType === 'multiple'" class="body-2 pseudo-link" (click)="selectedChange = element">
                details...
              </span -->
              <span class="body-2" *ngIf="element.changesType === 'single'">
                <app-object-visualizer [element]="element.newValue"></app-object-visualizer>
              </span>
            </td>
          </ng-container>

          <ng-container matColumnDef="actions" stickyEnd>
            <th mat-header-cell *matHeaderCellDef id="history-table__actions"></th>
            <td mat-cell *matCellDef="let element" id="history-table__actions">
              <div fxLayout="row" fxLayoutGap="10px">
                <button
                  mat-icon-button
                  class="accent"
                  type="button"
                  [disabled]="element.changesType === 'single'"
                  (click)="selectedChange = element"
                >
                  <mat-icon matSuffix>info</mat-icon>
                </button>
              </div>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
      </mat-card-content>

      <mat-card-content fxFlex class="sc-scrollable" *ngIf="dataSource.data.length === 0">
        <app-empty-state iconSrc="assets/icons/empty-states/head-hunting.png" message="No changes to audit has been found">
        </app-empty-state>
      </mat-card-content>

      <mat-paginator
        paginator
        (page)="getHistoryItems(false)"
        pageSize="10"
        [hidePageSize]="null"
        [pageSizeOptions]="[10, 20, 30, 50]"
        showFirstLastButtons
      >
      </mat-paginator>
    </mat-card>
  </div>
</div>

<ng-container *ngIf="selectedChange">
  <app-attribute-changes-detail
    class="sc-side-dialog"
    [(attribute)]="selectedChange"
    (onClose)="selectedChange = null"
  ></app-attribute-changes-detail>
</ng-container>

<svg id="svg" style="display: none">
  <defs>
    <filter id="bkFilter">
      <feColorMatrix
        color-interpolation-filters="sRGB"
        type="matrix"
        values="0   0   0   0   0
                0   0   0   0   0
                0   0   0   0   0
                0   0   0   0   0 "
      />
    </filter>
  </defs>
</svg>
