import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class UserPayloadService {
  private isUserArrive = false;

  public setUserArrive(isUserArrive: boolean) {
    this.isUserArrive = isUserArrive;
  }

  public getPayloadCreate(
    formUserPermission: any,
    formUserInformation: any,
    formNotification: any,
    companyRestrictions: string[],
    labels: any[],
    company: any
  ) {
    return {
      company: company.id,
      username: formUserInformation['userName'],
      aditionals: this.buildAditionals(formUserPermission),
      agreement: this.buildAgreement(),
      companyName: company.name,
      companyRestrictions,
      credentials: this.buildCredentials(formUserInformation),
      customStarkCore: this.buildNotification(formNotification),
      emailNotifications: this.buildEmailNotification(formNotification),
      isCustomer: formUserPermission[this.isUserArrive ? 'userType' : 'mainRole'] === 'customer',
      isSuperAdmin: formUserPermission[this.isUserArrive ? 'userType' : 'mainRole'] !== 'customer',
      profileInformation: this.buildProfileInformation(formUserInformation),
      userRole: formUserPermission[this.isUserArrive ? 'userType' : 'permission'],
      // password: formUserInformation['password'],
      office: this.isUserArrive ? formUserPermission['office'] : '',
      arriveRole: this.isUserArrive ? formUserPermission['role'] : '',
      boss: this.buildBossess(formUserPermission),
      labels,
    };
  }

  private buildProfileInformation(formUserInformation) {
    return {
      firstName: formUserInformation['firstName'],
      lastName: formUserInformation['lastName'],
      email: formUserInformation['userName'],
      occupation: formUserInformation['occupation'] ? formUserInformation['occupation'] : '-',
      phone: formUserInformation['phone'],
      src: '/',
    };
  }

  private buildBossess(formUserPermission) {
    if (!this.isUserArrive || !formUserPermission['boss']) {
      return [];
    }
    return formUserPermission['boss'].map((boss) => ({
      userId: boss._id,
      name: boss.name,
    }));
  }

  private buildAditionals(formUserPermission) {
    return {
      showCarrierRates: formUserPermission[this.isUserArrive ? 'userType' : 'mainRole'] !== 'customer',
      readOnly: false,
      canOverrideAdmin: false,
      subrole: formUserPermission[this.isUserArrive ? 'subRole' : 'permission'],
    };
  }

  private buildAgreement() {
    return {
      conscent: false,
      documents: {
        licenseAgreement: 'https://docs.google.com/document/d/1afx124hipyye_pnc0jf7ofogt2etdoepxogid0ekrru/edit?usp=sharing',
      },
    };
  }

  private buildNotification(formNotification) {
    return {
      dashboardReports: [],
      notifications: {
        desktop: !this.isUserArrive ? formNotification['desktop'] : false,
      },
      ownPricingFilter: !this.isUserArrive ? formNotification['desktop'] : false,
    };
  }

  private buildEmailNotification(formNotification) {
    return {
      shipment: !this.isUserArrive ? formNotification.get('email').value : false,
    };
  }

  private buildCredentials(formUserInformation) {
    return {
      username: formUserInformation['userName'],
    };
  }
}
