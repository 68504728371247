import { SelectionModel } from '@angular/cdk/collections';
import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { LabelService } from '@app-main/services/labels/label.service';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { displayNameMap } from '@shared/models/Breakpoint.model';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-create-companies-group',
  templateUrl: './create-companies-group.component.html',
  styleUrls: ['./create-companies-group.component.scss'],
})
export class CreateCompaniesGroupComponent implements OnChanges, OnDestroy {
  public currentScreenSize: string;
  ngUnsubscribe = new Subject<void>();

  @Input() companies;
  @Output() $saveLabel = new EventEmitter<any>();

  newGroupDS: MatTableDataSource<any>;
  newGroupSelection = new SelectionModel<any>(true, []);
  newLabelName: FormControl = new FormControl('', Validators.required);
  enableAddNewLabel = false;
  newLabelFilter: string;
  showNameLabel = false;

  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(private labelService: LabelService, private _breakpointObserver: BreakpointObserver) {
    this.newGroupDS = new MatTableDataSource([]);
    this.newGroupDS.filterPredicate = (data: any, filter: string) => {
      return data.name.trim().toLowerCase().includes(filter);
    };

    this._breakpointObserver
      .observe([Breakpoints.XSmall, Breakpoints.Small, Breakpoints.Medium, Breakpoints.Large, Breakpoints.XLarge])
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((result) => {
        for (const query of Object.keys(result.breakpoints)) {
          if (result.breakpoints[query]) {
            this.currentScreenSize = displayNameMap.get(query);
          }
        }
      });
  }

  async ngOnChanges() {
    await this.companies;
    this.newGroupDS.data = this.companies;
    this.newGroupDS.paginator = this.paginator;
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  saveLabel() {
    const payload = {
      name: this.newLabelName.value,
      type: 'companies',
      companies: this.newGroupSelection.selected,
      account: localStorage.getItem('account'),
    };
    this.labelService.createLabel(payload).subscribe((response) => {
      this.newLabelName.reset();
      this.$saveLabel.emit(response.data);
      this.enableAddNewLabel = true;
      this.newGroupSelection.clear();
    });
  }

  isMobile(): boolean {
    return this.currentScreenSize === 'XSmall' || this.currentScreenSize === 'Small';
  }

  filterNewGroupList(filterValue: string) {
    this.newGroupDS.filter = filterValue.trim().toLowerCase();
  }
}
