import { Component, Input, OnChanges, Output, SimpleChanges, EventEmitter } from '@angular/core';
import { AuthenticationService } from '@app-core/authentication/authentication.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
})
export class ToolbarComponent implements OnChanges {
  @Input() username: any;
  @Input() scAccount: any;
  @Input() companyName: any;
  @Input() companyLogo: any;
  @Output() onOpenDrawer: EventEmitter<any> = new EventEmitter();

  public capitalCharUsername: string;

  constructor(private authenticationService: AuthenticationService, private router: Router) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.username) {
      if (changes.username.currentValue) {
        this.capitalCharUsername = changes.username.currentValue.charAt(0).toUpperCase();
      }
    }
  }

  doOpenDrawer(): void {
    this.onOpenDrawer.emit(true);
  }

  logout(): void {
    this.authenticationService.logout();
    localStorage.removeItem('token');
    localStorage.removeItem('email');
    localStorage.removeItem('username');
    this.router.navigate(['/login']);
  }
}
