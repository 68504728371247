import { Component, Input } from '@angular/core';
import { UtilitiesService } from '@app-main/services/utilities/utilities.service';

@Component({
  selector: 'app-sys-label',
  templateUrl: './sys-label.component.html',
  styleUrls: ['./sys-label.component.scss'],
})
export class SysLabelComponent {
  // this can be 'environment' or 'version'
  @Input() info: string;

  public envLabel: string;
  public appVersion: string;

  constructor(private _scUtils: UtilitiesService) {
    this.envLabel = this._scUtils.getEnvironmentPreffix();
  }
}
