import { Injectable } from '@angular/core';
import { HttpService } from '@app-core/http/http.service';
import { Observable } from 'rxjs';
import { environment } from '@environments/environment';
import { HttpClient } from '@angular/common/http';
import { AccountService } from '../company/account.service';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(private http: HttpService, private httpClient: HttpClient, private accountService: AccountService) {}

  getUsersbyCompany(id): Observable<any> {
    const resource = `user/by-company/${id}`;
    return this.http.get<any>(resource, null);
  }

  getUserDetails(id): Observable<any> {
    const resource = `user/${id}/user-details`;
    return this.http.get<any>(resource, null);
  }

  getAccountUsers(resource = null, params = null): Observable<any> {
    const route = resource ? resource : `users?page=${0}&pageSize=${10}`;
    return this.http.post<any>(route, params);
  }

  getUserPermissions(): Observable<any> {
    const resource = 'permissions';
    return this.http.get<any>(resource, null);
  }

  getPermissionsArrive(userType: string): Observable<any> {
    const params = {
      isArrive: true,
      userRole: userType,
    };
    const resource = 'users/arrive-permissions';
    return this.http.get(resource, params);
  }

  postAddDeleteUsers(body: any): Observable<any> {
    const resource = 'user/add-delete-users';
    return this.http.post<any>(resource, body, { observe: 'response' });
  }

  createUser(body: any): Observable<any> {
    const resource = 'user';
    return this.http.post<any>(resource, body, { observe: 'response' });
  }

  updateUser(id, body: any): Observable<any> {
    const resource = `user/${id}`;
    return this.http.put<any>(resource, body, { observe: 'response' });
  }

  getUser(id): Observable<any> {
    const resource = `user/${id}`;
    return this.http.get<any>(resource, null);
  }

  getDeleteUser(id): Observable<any> {
    const resource = `user/delete/${id}`;
    return this.http.delete<any>(resource);
  }

  resetPassword(userEmail: string): Observable<any> {
    return this.httpClient.post(
      environment.auth0Domain,
      {
        client_id: environment.auth0Id,
        email: userEmail,
        connection: 'Username-Password-Authentication',
      },
      {
        responseType: 'text',
      }
    );
  }

  getInmediateBoss(office: string, role: string) {
    const body = {
      office,
      role,
    };
    const resource = 'get-boss';
    return this.http.post(resource, body);
  }

  getRolesForArrive() {
    const resource = 'roles';
    return this.http.get(resource, null);
  }

  getSubRolesForArrive(userRole: string) {
    const body = {
      isArrive: true,
      userRole,
    };
    const resource = 'roles/arrive-permissions';
    return this.http.get(resource, body);
  }

  getColumns(): string[] {
    return this.accountService.isArrive
      ? ['firstName', 'lastName', 'role', 'subrole', 'userRole', 'email', 'actions']
      : ['firstName', 'lastName', 'userRole', 'email', 'actions'];
  }
}
