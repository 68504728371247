<section fxLayout="column" fxLayoutGap="12px">
  <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="6px">
    <mat-icon>expand_more</mat-icon>
    <h2 class="primary-text">{{ obj.label }}:</h2>
  </div>
  <div style="padding-left: 18px" fxLayout="column" fxLayoutGap="16px">
    <div fxLayout="row" fxLayoutGap="16px" *ngFor="let attribute of getObjAsAnArray(obj.value)">
      <app-primitive-element *ngIf="!obj.isPrimitive" [primitive]="attribute"></app-primitive-element>
      <app-object-element *ngIf="obj.isPrimitive" [obj]="attribute"></app-object-element>
    </div>
  </div>
</section>
