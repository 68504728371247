import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { environment } from '@environments/environment';
import { catchError, map } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { throwError } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class HttpService {
  constructor(private http: HttpClient, private snackBar: MatSnackBar) {}

  private token = localStorage.getItem('token');

  private readonly headerConfig = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json; charset=UTF-8',
      'Content-Language': 'en',
      Authorization: 'Bearer ' + this.token,
    }),
  };

  public getStarkCore<t>(endPoint, param) {
    return this.http.get<t>(environment.apiUrlStarkcore + endPoint, param);
  }

  public postStarkCore<t>(endPoint, param) {
    return this.http.post<t>(environment.apiUrlStarkcore + endPoint, param);
  }

  public postAuth0<t>(userEmail) {
    return this.http.post<t>(environment.auth0Domain, {
      client_id: environment.auth0Id,
      email: userEmail,
      connection: 'Username-Password-Authentication',
    });
  }

  public get<T>(endPoint: string, params: any) {
    return this.http.get<T>(environment.apiUrl + endPoint, { params }).pipe(catchError(this.handleError.bind(this)));
  }

  public post<t>(endPoint: string, body: any, options?: any) {
    return this.http
      .post<t>(environment.apiUrl + endPoint, body, options)
      .pipe(map(this.handleSuccess.bind(this)), catchError(this.handleError.bind(this)));
  }

  public put<t>(endPoint, body, options?) {
    return this.http
      .put<t>(environment.apiUrl + endPoint, body, options)
      .pipe(map(this.handleSuccess.bind(this)), catchError(this.handleError.bind(this)));
  }
  public patch<t>(endPoint, body, options?) {
    return this.http
      .patch<t>(environment.apiUrl + endPoint, body, options)
      .pipe(map(this.handleSuccess.bind(this)), catchError(this.handleError.bind(this)));
  }
  public delete<t>(endPoint, body?) {
    let option;
    if (body) {
      option = {
        ...this.headerConfig,
        body,
      };
    } else {
      option = { ...this.headerConfig };
    }
    return this.http
      .delete<t>(environment.apiUrl + endPoint, option)
      .pipe(map(this.handleSuccess.bind(this)), catchError(this.handleError.bind(this)));
  }

  private handleError(error: HttpErrorResponse) {
    let message = '';
    if (Object.prototype.hasOwnProperty.call(error, 'error') && Object.prototype.hasOwnProperty.call(error.error, 'status')) {
      message = `${error.error.message}`;
    } else if (Object.prototype.hasOwnProperty.call(error, 'error') && Object.prototype.hasOwnProperty.call(error.error, 'message')) {
      message = error.error.message;
    } else {
      message = 'Error';
    }
    this.snackBar.open(message, 'Close', {
      duration: 5000,
      horizontalPosition: 'right',
      verticalPosition: 'top',
      panelClass: ['error', 'sc-snackbar'],
    });
    return throwError(error);
  }

  private handleSuccess(response: any) {
    if (response?.body?.message) {
      const message = Object.prototype.hasOwnProperty.call(response, 'body.message')
        ? response.body.message
        : Object.prototype.hasOwnProperty.call(response, 'data')
        ? response.message
        : Object.prototype.hasOwnProperty.call(response, 'statusText')
        ? response.body.message
        : 'Success';

      this.snackBar.open(message, 'Close', {
        duration: 3000,
        horizontalPosition: 'right',
        verticalPosition: 'top',
        panelClass: ['success', 'snack-admin-succes', 'sc-snackbar'],
      });
    }

    return response;
  }
}
