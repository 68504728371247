<mat-progress-bar color="primary" mode="indeterminate" *ngIf="spinnerService.visibility | async"></mat-progress-bar>

<section fxLayout.sm="column" fxLayout="row" id="main-layout">
  <app-sidenav
    [username]="username"
    [companyLogo]="companyLogo"
    [openDrawer]="showDrawer"
    (onCloseDrawer)="showDrawer = $event"
  ></app-sidenav>

  <div>
    <app-toolbar
      [companyName]="companyName"
      [companyLogo]="companyLogo"
      [username]="username"
      [scAccount]="scAccount"
      (onOpenDrawer)="showDrawer = $event"
    ></app-toolbar>
    <div id="main-content">
      <router-outlet></router-outlet>
    </div>
  </div>
</section>
