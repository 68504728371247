import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

import { SpinnerService } from '@app-main/services/spinner/spinner.service';
import { ActivationEnd, Router } from '@angular/router';
import { HttpCancelService } from './httpCancel.service';

@Injectable({
  providedIn: 'root',
})
export class InterceptorService implements HttpInterceptor {
  constructor(private spinnerService: SpinnerService, router: Router, private httpCancelService: HttpCancelService) {
    router.events.subscribe((event) => {
      if (event instanceof ActivationEnd) {
        this.httpCancelService.cancelPendingRequests();
      }
    });
  }

  // Intercept the request to set header.
  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    setTimeout(() => {
      this.spinnerService.show();
    }, 0);
    const token = localStorage.getItem('token');
    const params = {
      'Content-Language': 'en',
    };

    if (req.url.indexOf('login') === -1 && token) {
      req = req.clone({
        setHeaders: { ...params, Authorization: 'Bearer ' + token },
      });
    } else {
      req = req.clone({
        setHeaders: params,
      });
    }

    return next.handle(req).pipe(
      tap(
        (event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            setTimeout(() => {
              this.spinnerService.hide();
            }, 0);
          }
        },
        () => {
          setTimeout(() => {
            this.spinnerService.hide();
          }, 0);
        }
      ),
      takeUntil(this.httpCancelService.onCancelPendingRequests())
    );
  }
}
