import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-drawer-header',
  templateUrl: './drawer-header.component.html',
  styleUrls: ['./drawer-header.component.scss'],
})
export class DrawerHeaderComponent {
  @Input() title: string;
  @Input() totalList: string;
  @Input() searchFilter: any;
  @Output() onCreateAction = new EventEmitter<any>();

  onClickAdd(): void {
    this.onCreateAction.emit();
  }
}
