import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss'],
})
export class SearchBarComponent {
  private _filter: string;
  @Input() set filter(value: string) {
    this._filter = value;
  }
  get filter(): string {
    return this._filter;
  }
}
